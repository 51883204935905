import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Modal, Spinner } from '@/components/__UI'
import { PropertyId, PropertyName, BookingType } from '../bookingModals.styles'
import BookingDetails from '@/components/__BookingComponents/BookingDetails'
import { useTranslation } from 'react-i18next'
import { pascalToCamelCase } from '@/helpers/stringUtils'
import { DeleteBin } from '@awaze/design-system.components.icon'
import { Button } from '@awaze/design-system.components.button'

const BookedModal = ({
  data,
  canCancel,
  loading,
  onClose,
  onCancel
}) => {
  const { t } = useTranslation()

  const selectedType = data?.state ? data.state : null

  return (
    <Modal open onClose={onClose}>
      {(loading || !data)
        ? (<Spinner large />)
        : (
          <>

            <BookingType bookingState={selectedType}>{t(pascalToCamelCase(selectedType))}</BookingType>

            <PropertyName>{data?.propertyName}</PropertyName>
            <PropertyId>{data?.propertyId}</PropertyId>
            <BookingDetails booking={data} style={{marginBottom: '1.5rem'}}/>
            <div style={{ marginLeft: '2rem' }}>
              {(!dayjs(data.startDate).isBefore(dayjs(), 'day') && data.cancellable && canCancel) ? (
                <Button
                  data-testid="bookingCancelButton"
                  onClick={() => {
                    onCancel && onCancel(data.bookingId)
                    onClose && onClose()
                  }}
                  icon={<DeleteBin />}
                  iconPosition='left'
                  colourScheme='brand-secondary'
                  text={t('cancelBooking')}
                  variant='outline'
                />
              ) : (
                <Button
                  data-testid="bookingModalConfirmButton"
                  onClick={onClose}
                  colourScheme='brand-primary'
                  text={t('ok')}
                  size='medium'
                  style={{width: '124px'}}
                />

              )}
            </div>

          </>
        )}
    </Modal>
  )
}

BookedModal.displayName = 'BookedModal'

BookedModal.propTypes = {
  data: PropTypes.object,
  canCancel: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  isChart: PropTypes.bool,
  type: PropTypes.string
}

export default BookedModal
