import styled from 'styled-components'

/* stylelint-disable-line block-no-empty */
const MainHeaderSelectContainer = styled.article``

const ExplanationText = styled.div`
  .explanationParagraph{
    margin-bottom: 1rem;
    text-align: justify;
    
    .linkSpan{
      cursor: pointer;
      display: inline-block;
      position: relative;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      text-decoration: underline;
      transition: color 0.2s ease-in-out;
      
      &:hover{
        color: ${({ theme }) => theme.colors.secondaryDarken};
      }
    }
  }



${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 100%;
    margin: 1rem 0;
}
`

export { MainHeaderSelectContainer, ExplanationText }
