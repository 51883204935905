import React from 'react'
import {RecommendationPropertyChoicesStepTwo} from './PriceRecommendationsFooter.styles'
import AcceptanceTable from '../AcceptanceTable/AcceptanceTable'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useUserBrand from '@/hooks/useUserBrand'

const PriceRecommendationsFooter = ({filteredProperties}) => {
  const { t } = useTranslation()
  const { isNovasol } = useUserBrand()

  const filteredPropertiesWithAccepted = filteredProperties && filteredProperties.map(i => {
    i.isAccepted = true
    return i
  })

  const explanationParagraph = () => {
    return isNovasol ? <div>
      <h4>{t('priceFooterTextDefault1')}</h4>
      <ul style={{listStyleType: 'disc'}}>
        <li> &nbsp; {t('priceFooterTextDefault2')}</li>
        <li> &nbsp; {t('priceFooterTextDefault3')}</li>
      </ul>
    </div>
      : <div>
        <h4>{t('priceFooterTextStart1')}</h4>
        <ul style={{listStyleType: 'disc'}}>
          <li> &nbsp; {t('priceFooterTextStart2')}</li>
          <li> &nbsp; {t('priceFooterTextStart3')}</li>
        </ul>
      </div>
  }

  return (
    <RecommendationPropertyChoicesStepTwo>
      {explanationParagraph()}

      <AcceptanceTable filteredProperties={filteredPropertiesWithAccepted}/>

    </RecommendationPropertyChoicesStepTwo>
  )
}
PriceRecommendationsFooter.propTypes = {
  filteredProperties: PropTypes.array
}

export default PriceRecommendationsFooter
