import styled from 'styled-components'
import { Flex } from 'reflexbox/styled-components'
import { getConsolidatedBookingColor } from '@/helpers/bookings'

const BookingType = styled.p`
  font-size: .688em;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: sub;
    width: .813rem;
    height: .813rem;
    border-radius: 50%;
    margin-right: .5rem;
    background: ${({ bookingState }) => getConsolidatedBookingColor(bookingState)};
}
`

const PropertyName = styled.h4`
  margin-top: 1.5rem;
  ${({ theme }) => theme.fonts.Heading200}
`

const PropertyId = styled.small`
display: flex;
  ${({ theme }) => `
    margin-bottom: 1rem;
    font-size: .75em;

    ${theme.mediaQueries.phoneOnly} {
      margin-bottom: 1rem;
    }
  `}
`

const BookingDetails = styled(Flex)`
  width: auto;
  font-size: 0.75rem;

  h4 {
    margin-bottom: 1rem;
  }

  td {
    padding: 0 0 1rem;
  }

  td:nth-child(2) {
    font-weight: 600;
    padding-left: 2rem;
  }
`

const StyledTable = styled.table`
  width: 100%;
`

const ConfirmationMessage = styled.div`
  margin: 1rem 0 2rem;

  h3 {
    font-weight: 600;
  }
`

const ErrorMessage = styled(ConfirmationMessage)`
  background-color: ${({theme}) => theme.colors.bookingModalBackgroundError};
  padding: 1rem;
  color: ${({theme}) => theme.colors.white};
  line-height: 1.8em;
  border-radius: 3px;

  b {
    font-weight: bold;
  }
`

const ErrorText = styled.span`
  color: ${({theme}) => theme.colors.error};
  font-size: .75em;
`

const ExcessCharges = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.bookingModalBackgroundExcessCharges};
  padding: 0.5rem 1rem;
  border-radius: 3px;
  height: 100%;
  pointer-events: none;

  &::before {
  position: absolute;
  top: -0.8rem;
  left: -0.8rem;
  content: "!";
  height: 25px;
  width: 25px;
  background-color: ${({theme}) => theme.colors.bookingModalBackgroundExcessChargesBefore};
  border-radius: 50%;
  color: ${({theme}) => theme.colors.white};
  text-align: center;
  font-size: 1.5em;
  line-height: 25px;
}
h4 {
  font-weight: 600;
}
p {
  margin-top: 1rem;
}
`

export {
  BookingType,
  PropertyName,
  PropertyId,
  BookingDetails,
  StyledTable,
  ConfirmationMessage,
  ErrorMessage,
  ErrorText,
  ExcessCharges
}
