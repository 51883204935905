import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { PageWrapper, Spinner } from '@/components/__UI'
import { useMappedUrl } from '@/hooks/useUrlMappings'
import { getEnvironment } from '@/helpers/environment'
import { useTranslation } from 'react-i18next'

const NotPermittedPage = ({ permittedByPermissions, permittedByBrand, permittedByProductMarket, brand, path }) => {
  const { t } = useTranslation()

  const shouldRedirect = useMemo(() =>
    permittedByPermissions &&
      (
        !permittedByBrand ||
        !permittedByProductMarket
      ) &&
      brand !== 'awaze'
  , [permittedByPermissions, permittedByBrand, permittedByProductMarket, brand])
  const isLocalhost = getEnvironment() === 'localhost'
  const willRedirect = shouldRedirect && !isLocalhost

  const { url, isLoading } = useMappedUrl(path, brand)

  useEffect(() => {
    if (willRedirect && isLoading === false) {
      window.location.replace(url)
    }
  }, [willRedirect, url, isLoading])

  if (willRedirect) {
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <p data-testid="generalNotPermittedError">{t('unauthorizedAccess')}</p>
    </PageWrapper>
  )
}

NotPermittedPage.propTypes = {
  permittedByPermissions: PropTypes.bool,
  permittedByBrand: PropTypes.bool,
  permittedByProductMarket: PropTypes.bool,
  brand: PropTypes.string,
  path: PropTypes.string
}

export default NotPermittedPage
