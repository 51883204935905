import { useUser } from '@/context/UserProvider'

export function useUserBrand () {
  const { user } = useUser()
  const brand = user?.attributes.brand

  let userType = null
  let isAwaze = false
  let isNovasol = false
  const userProductMarkets = user?.attributes?.productMarkets

  if (brand === 'novasol') {
    userType = brand
    isNovasol = true
  } else if (brand === 'awaze') {
    userType = brand
    isAwaze = true
  }

  return { userType, isAwaze, isNovasol, userProductMarkets }
}

export default useUserBrand
