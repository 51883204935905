/* eslint-disable react/prop-types */
import React from 'react'
import {
  SectionTitle,
  SectionContent,
  Section,
  SectionIcon,
  ItemsContainer,
  ItemTitle,
  ItemInfo,
  Item,
  ColumnsContent,
  ColumnsContentCostUnit,
  StyledSpecialIconContainer,
  PriceBreakdownContainer,
  ExtrasItem,
  NoWrapParagraph
} from '../BookingDetails.styles'
import ExtrasSvg from '@/components/__SVG/ExtrasSvg'
import SpecialRequestsIcon from '../../../__SVG/SpecialRequestsIcon'
import Theme from '@/global/Theme'
import { useTranslation } from 'react-i18next'
import {filterBookingExtras} from '@/helpers/filterBookingExtras/filterBookingExtras'
import { getLocale } from '@/helpers/locale'

export enum CostType {
  BasePrice = 'BasePrice',
  Extra = 'Extra',
  Fee = 'Fee',
  Discount = 'Discount',
  Other = 'Other',
  LocallyPayableExtra = 'LocallyPayableExtra',
  None = ''
}

export enum CostUnit {
  NOT_SET = 'NOT_SET',
  PRICE_PER_DAY = 'PRICE_PER_DAY',
  PRICE_PER_WEEK = 'PRICE_PER_WEEK',
  PRICE_PER_STAY = 'PRICE_PER_STAY',
  PRICE_PER_PERSON = 'PRICE_PER_PERSON',
  PRICE_PER_UNIT = 'PRICE_PER_UNIT',
  PRICE_PER_DAY_PER_PERSON = 'PRICE_PER_DAY_PER_PERSON',
  PRICE_PER_WEEK_PER_PERSON = 'PRICE_PER_WEEK_PER_PERSON',
  PRICE_PER_HOUR = 'PRICE_PER_HOUR',
  PRICE_PER_KWH = 'PRICE_PER_KWH',
  PRICE_PER_CUBIC_METRE = 'PRICE_PER_CUBIC_METRE',
  PRICE_PER_LITRE = 'PRICE_PER_LITRE',
  PRICE_PER_MWH = 'PRICE_PER_MWH',
  PRICE_FOR_FOUR_HOURS = 'PRICE_FOR_FOUR_HOURS',
  PRICE_FOR_EIGHT_HOURS = 'PRICE_FOR_EIGHT_HOURS',
  PRICE_FOR_FIVE_DAYS = 'PRICE_FOR_FIVE_DAYS',
  PRICE_PER_SET = 'PRICE_PER_SET',
  PRICE_PER_BAG = 'PRICE_PER_BAG'
}

export interface Extra {
  id: string;
  itemQty: number;
  name: string;
  hideCost: boolean;
  cost: number;
  hideQuantity: boolean;
  costType: CostType;
  costUnit: CostUnit;
  costCurrency: string;
}

interface PriceSectionProps {
  extras: Extra[];
  holidayPrice?: number;
  specialRequirements?: string[];
  currency?: string;
}

export const priceSectionHasData = ({
  extras,
  holidayPrice = 0,
  specialRequirements = []
}: PriceSectionProps): boolean => {
  const hasValidExtra = extras?.some(extra => extra.itemQty && extra.itemQty > 0)

  return (
    (extras && extras.length > 0 && hasValidExtra) ||
    holidayPrice > 0 ||
    specialRequirements.length > 0
  )
}

export const PriceSection = ({ extras, holidayPrice, specialRequirements, currency }: PriceSectionProps) => {
  const { t } = useTranslation()
  if (!priceSectionHasData({ extras, holidayPrice, specialRequirements })) return null

  const titleToDisplay = holidayPrice ? t('costBreakdownTitle') : t('extrasAndServicesTitle')

  const renderExtras = () => {
    if (extras.length === 0 && !holidayPrice) return null

    const { locallyPayableExtras, notLocallyPayableExtras } = filterBookingExtras(extras)
    const hasLocallyPayableExtras = locallyPayableExtras.length > 0

    return (
      <PriceBreakdownContainer>
        {notLocallyPayableExtras.length > 0 && notLocallyPayableExtras.map(({
          name,
          itemQty,
          cost,
          costCurrency,
          hideCost,
          hideQuantity,
          id
        }) => {
          if (!itemQty) return null
          const key = `${name}-${itemQty}-${cost}`
          const translationKey = id ? 'extras' + id : name
          return (
            <ColumnsContent key={key}>
              <p>
                {!hideQuantity && `${itemQty} x `}
                {t(translationKey, { defaultValue: name })}
              </p>
              {(!hideCost) && <NoWrapParagraph>{`${cost.toLocaleString(getLocale())} ${costCurrency ?? currency}`}</NoWrapParagraph>}
            </ColumnsContent>
          )
        })}

        {hasLocallyPayableExtras && <SectionContent style={{marginTop: '1rem'}}>
          <ItemTitle>{t('paidAfterArrival')}</ItemTitle>
          <ItemInfo style={{fontWeight: 'normal', marginBottom: '1rem', color: Theme.ColorGrey600}}>{t('costToBePaidAfterArrival')}</ItemInfo>
          {locallyPayableExtras.map(({
            name,
            cost,
            costUnit,
            costCurrency,
            itemQty,
            hideCost,
            id
          }) => {
            const key = `${name}-${itemQty}-${cost}`
            const showCost = !hideCost && costUnit !== CostUnit.NOT_SET
            return (
              <ExtrasItem key={key}>
                <ColumnsContent>
                  <p>{t(`extras${id}`, { defaultValue: name })}</p>
                  {showCost && <NoWrapParagraph>{`${cost.toLocaleString(getLocale())} ${costCurrency ?? currency}`}</NoWrapParagraph>}
                </ColumnsContent>
                {showCost &&
                  <ColumnsContentCostUnit data-testid={'price-section-locally-payable-extras-cost-unit-info'}>
                    {t(`enum:cost_unit:${costUnit}`)}
                  </ColumnsContentCostUnit>
                }
              </ExtrasItem>
            )
          })}
        </SectionContent>}

        {holidayPrice !== 0 && holidayPrice && (
          <ColumnsContent style={{
            borderTop: `1px solid ${Theme.ColorGrey100}`,
            paddingTop: '0.375rem'
          }}>
            <p style={{fontWeight: 'bold'}}>
              {t('totalHolidayPrice')}:
            </p>
            <p style={{fontWeight: 'bold'}}>
              {`${holidayPrice} ${currency}`}
            </p>
          </ColumnsContent>
        )}

      </PriceBreakdownContainer>
    )
  }

  const renderSpecialRequirements = () => {
    if (!specialRequirements || specialRequirements.length === 0) return null

    return (
      <Item>
        <ItemTitle>
          <StyledSpecialIconContainer>{t('specialRequest')} <SpecialRequestsIcon /></StyledSpecialIconContainer>
        </ItemTitle>
        <ItemInfo style={{marginTop: '-0.5rem'}}>
          <p>
            { Array.isArray(specialRequirements) && specialRequirements.map(r => r) }
          </p>
        </ItemInfo>
      </Item>
    )
  }

  return (
    <Section data-testid='pricing-section'>
      <SectionIcon><ExtrasSvg /></SectionIcon>
      <SectionContent>
        <SectionTitle>
          {titleToDisplay}
        </SectionTitle>
        <ItemsContainer>
          {renderExtras()}
          {renderSpecialRequirements()}
        </ItemsContainer>
      </SectionContent>
    </Section>
  )
}
