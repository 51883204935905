import styled from 'styled-components'

const RecommendationPropertyChoicesStepTwo = styled.div`
margin-top: 2rem;
  h4{
    margin: 0.3rem 0 0.4rem 0;
    font-weight: 600;
  }

ul{
  margin-left: 0.5rem;
  padding-left: 1em;
  text-indent: -1em;
  text-align: justify;
}

ul li:before {
    content: none;
}

ul li{
  margin-bottom: 0.3rem;
}
`

export { RecommendationPropertyChoicesStepTwo }
