import React, { useEffect, useMemo } from 'react'
import { Spinner } from '@/components/__UI'
import { GridWrap, StyledPreviousButton } from './BookingCalendar.styles'
import useWindowSize from '@/hooks/useWindowSize'
import dayjs from 'dayjs'
import { Box } from 'reflexbox/styled-components'
import Calendar from '@/components/Calendar'
import { useInfiniteQueryBookings } from '@/hooks/useQuery'
import { CONSOLIDATED_BOOKING_STATE_CANCELLED } from '@/constants/bookings'
import { InfiniteScrollTrigger } from '@/components/InfiniteScrollTrigger'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const getCalendarLayout = yearCalendarInput => yearCalendarInput ? [1] : [1, 1, 1 / 3, 1 / 3]
const getCalendarIpadLayout = yearCalendarInput => yearCalendarInput ? [1] : [1, 1, 1 / 2, 1 / 2]

export function BookingCalendarMonth ({
  year,
  yearCalendar,
  propertyId,
  unitId,
  setSelectedDay,
  setSelectedBookingID,
  hasAuth,
  setBookings,
  setClosures,
  contractExpiry
}) {
  const { width } = useWindowSize()
  const { t } = useTranslation()
  const calendarLayout = width < 1025 ? getCalendarIpadLayout(yearCalendar) : getCalendarLayout(yearCalendar)
  const enabled = !yearCalendar && !!propertyId

  const {
    data,
    isLoading: loadingBookings,
    fetchPreviousPage,
    fetchNextPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isFetchingNextPage
  } = useInfiniteQueryBookings({ year, yearCalendar, propertyId, unitId, options: { enabled } })

  const pages = data?.pages
  const closures = useMemo(() => pages?.flatMap(page => page.closures), [pages])
  const bookings = useMemo(() => {
    let bookings = pages?.flatMap(page => page.bookings.bookings) ?? []
    bookings = bookings.filter(x => x.state !== CONSOLIDATED_BOOKING_STATE_CANCELLED)

    // TODO filtering unique bookings due to https://awazevr.atlassian.net/browse/OUK-9023
    const uniqueBookings = new Map()
    for (const booking of bookings) {
      uniqueBookings.set(booking.bookingId, booking)
    }
    bookings = Array.from(uniqueBookings.values())
    return bookings
  }, [pages])

  useEffect(() => {
    enabled && setBookings(bookings)
  }, [enabled, bookings, setBookings])
  useEffect(() => {
    enabled && setClosures(closures)
  }, [enabled, closures, setClosures])

  const monthsList = useMemo(() => {
    if (!(pages?.length > 0)) return []
    const startDate = pages[0].bookings?.fromDate
    const endDate = pages[pages.length - 1].bookings.toDate

    let currentMonth = dayjs(startDate).add(1, 'month')
    const lastMonth = dayjs(endDate)

    const monthsList = []

    while (currentMonth.isBefore(lastMonth) || currentMonth.isSame(lastMonth, 'month')) {
      monthsList.push(currentMonth.format('YYYY-MM-DD'))
      currentMonth = currentMonth.add(1, 'month')
    }
    return monthsList
  }, [pages])

  return (
    <>
      {loadingBookings ? <Spinner large />
        : isFetchingPreviousPage ? (
          <Spinner />
        ) : (
          hasPreviousPage &&
            <StyledPreviousButton
              iconPosition="left"
              onClick={() => {
                fetchPreviousPage()
              }}
              text={t('showPreviousMonths')}
              colourScheme="brand-primary"
              variant="text"
            />
        )
      }
      <GridWrap style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>
        {monthsList.map(date => {
          const segments = bookings ? bookings.filter(b => {
            const startDate = dayjs(b.startDate)
            const endDate = dayjs(b.endDate)
            const firstDayOfMonth = dayjs(date).startOf('month')
            const lastDayOfMonth = dayjs(date).endOf('month')

            const overlapsCurrentMonth = startDate.isBefore(lastDayOfMonth, 'day') && endDate.isAfter(firstDayOfMonth, 'day')

            const isSameMonth = startDate.isSame(date, 'month') || endDate.isSame(date, 'month')

            return isSameMonth || overlapsCurrentMonth
          }) : []

          return (
            <Box key={date} width={calendarLayout} mb={2}>
              <Calendar
                date={dayjs(date)}
                onDayClick={setSelectedDay}
                onSegmentClick={({ id }) => setSelectedBookingID(id)}
                segments={segments.map(item => ({
                  id: item.bookingId,
                  state: item.state,
                  startDate: item.startDate,
                  endDate: item.endDate
                }))}
                unavailableDates={[...closures, contractExpiry] || []}
                disabled={!hasAuth('createBooking')}
                yearCalendar={yearCalendar}
              />
            </Box>
          )
        })}
        {isFetchingNextPage && <Spinner small />}
        <InfiniteScrollTrigger onVisible={() => fetchNextPage()} style={{ width: '100%' }}/>
      </GridWrap>
    </>

  )
}

BookingCalendarMonth.propTypes = {
  year: PropTypes.number.isRequired,
  yearCalendar: PropTypes.bool,
  propertyId: PropTypes.string,
  unitId: PropTypes.string,
  setSelectedDay: PropTypes.func.isRequired,
  setSelectedBookingID: PropTypes.func.isRequired,
  hasAuth: PropTypes.func.isRequired,
  setBookings: PropTypes.func.isRequired,
  setClosures: PropTypes.func.isRequired,
  contractExpiry: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startDateIso: PropTypes.string,
    endDateIso: PropTypes.string
  })
}
