import React, {useState} from 'react'
import { Title, ButtonSection, CancelButton, StyledAcceptModal, PropertyList, CloseButton, ConfirmButton } from './AcceptanceModal.styles'
import toast from '@/helpers/toast'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeyPropertyPricingSummary, queryKeyUserProperties, useMutationRecommendedPricingUpdate } from '@/hooks/useQuery'
import { getLocale } from '@/helpers/locale'

function groupByYear (filteredProperties) {
  const propertiesByYear = {}

  for (const pr of filteredProperties) {
    if (pr.years?.length > 0) {
      for (const { year } of pr.years) {
        if (propertiesByYear[year] == null) {
          propertiesByYear[year] = []
        }
        propertiesByYear[year].push({
          propertyId: pr.propertyId,
          propertyCode: pr.propertyCode,
          recommendationState: pr.isAccepted ? 'approved' : 'rejected'
        })
      }
    }
  }

  return propertiesByYear
}

const AcceptanceModal = ({modalOpen, setModalOpen, filteredProperties}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { mutateAsync: updateRecommendedPricing } = useMutationRecommendedPricingUpdate()

  const [successModal, setSuccessModal] = useState(false)

  const sendPropertiesState = async () => {
    setSuccessModal(true)

    const propertiesByYear = groupByYear(filteredProperties)

    try {
      const queries = Object.entries(propertiesByYear).map(([year, approvedOrRejectedProperties]) => {
        return updateRecommendedPricing({
          year: Number(year),
          language: getLocale(),
          referenceDataToUpdate: approvedOrRejectedProperties
        })
      })

      const response = await Promise.all(queries)
      const success = response.every(resp => resp.success)

      if (success) {
        queryClient.invalidateQueries(queryKeyPropertyPricingSummary)
        queryClient.invalidateQueries(queryKeyUserProperties('viewFinancialReports'))
      } else {
        toast.error(t('errorMessageSomethingWentWrong'))
      }
    } catch {
      toast.error(t('errorMessageSomethingWentWrong'))
    }
  }

  return (
    !successModal
      ? <StyledAcceptModal open={modalOpen} onClose={setModalOpen} >
        <Title>{t('areYouSurePleaseConfirm')}</Title>
        {
          filteredProperties.map((item, index) => {
            return (
              <PropertyList key={index} style={{listStyleType: 'disc'}}>
                <li>{item.propertyName} - </li>
                <li>
                  {filteredProperties[index].isAccepted
                    ? <span className='accepted'>{t('accepted')}</span>
                    : <span className='rejected'>{t('rejected')}</span>}
                </li>
              </PropertyList>
            )
          })
        }
        <ButtonSection>
          <CancelButton data-testid="acceptanceModalCancelButton" primaryLine fullWidth onClick={setModalOpen}>
            {t('cancel')}
          </CancelButton>
          <ConfirmButton
            data-testid="confirmBtn"
            primary
            fullWidth
            onClick={sendPropertiesState}
          >
            {t('confirm')}
          </ConfirmButton>
        </ButtonSection>
      </StyledAcceptModal>

      : <StyledAcceptModal open={modalOpen} onClose={setModalOpen} strict >
        <Title>{t('thankYouYourChoiceIsReceived')}</Title>
        <p className='confirmationMessage'>{t('weWillDoTheRequiredRegistrationAsSoonAsPo')}</p>
        <ButtonSection>
          <Link to='/pricing-list'>
            <CloseButton
              data-testid="acceptanceModalCloseButton"
              primary
              fullWidth
              onClick={setModalOpen}
            >
              {t('close')}
            </CloseButton>
          </Link>
        </ButtonSection>
      </StyledAcceptModal>
  )
}

AcceptanceModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  filteredProperties: PropTypes.array
}

export default AcceptanceModal
