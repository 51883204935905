import React, {useEffect} from 'react'
import { useUser } from '@/context/UserProvider'
import { PageWrapper } from './SignOutPage.styles'
import { Spinner } from '@/components/__UI'

const SignOutPage = () => {
  const { logout } = useUser()

  useEffect(() => {
    logout()
  }, [logout])

  return (
    <PageWrapper>
      <Spinner />
    </PageWrapper>
  )
}

export default SignOutPage
