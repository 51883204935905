import React from 'react'
import { Oval } from 'react-loader-spinner'
import { consts } from '../../../global/Theme'

const OvalLoadingSpinner = () => (
  <Oval
    visible={true}
    color={consts.colors.buttonSolidPrimaryHover}
    secondaryColor="lightgrey"
    height={32}
    width={32}
    ariaLabel="oval-loading"
  />
)

export default OvalLoadingSpinner
