/* stylelint-disable no-descending-specificity */

import styled from 'styled-components'
import Button from '@/components/__Inputs/Button'
import Modal from '@/components/__UI/Modal'
import DesignTokens from '@/global/DesignTokens'

const StyledAcceptModal = styled(Modal)`
    max-width: 40rem;
    padding: 1rem 1rem 1.5rem;

    ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
      padding: 1rem 3rem 1.5rem;
    }
`

const Title = styled.h2`
  text-align: center;
  margin: 1rem 0 1rem;
`

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  
  button {
    flex: 1;
    max-width: 10rem;

    &:first-child {
      margin-right: 0.5rem;
    }
    &:nth-child(2n) {
      margin-left: 0.5rem;
    }
  }
`

const CancelButton = styled(Button)`
    background-color: ${DesignTokens.ColorButtonSolidReverseBackground};
    border: 2px solid ${DesignTokens.ColorButtonOutlinePrimaryBorder};
    color: ${DesignTokens.ColorButtonOutlinePrimaryText};
    transition: all .7s;
    /* attentionDarken, or primary */

    &:hover{
      border-color: 2px solid ${DesignTokens.ColorButtonOutlinePrimaryBorderHover};
      color: ${DesignTokens.ColorButtonOutlinePrimaryTextHover};
    }
`
/* stylelint-disable-line block-no-empty */
const ConfirmButton = styled(Button)``
const CloseButton = styled(Button)`
    width: 20rem;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      /* change max-width here in file in 'button' on line 28 if needed */
    }
`

const PropertyList = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  padding-left: 1em;
  text-indent: -1em;
  text-align: justify;

ul li:before {
    content: none;
}
ul li{
  margin-bottom: 1rem;
}
li{
  margin-bottom: 0.5rem;
}
li:nth-child(2){
  list-style-type: none;
  margin-left: 1.3rem;
}
span.accepted {
  color: ${({theme}) => theme.colors.success};
}
span.rejected {
  color: ${({theme}) => theme.colors.error};
}
`

export { Title, ButtonSection, CancelButton, StyledAcceptModal, PropertyList, CloseButton, ConfirmButton }
