import React from 'react'
import { Select, SelectProps } from '@awaze/design-system.components.select'

import { Label } from './SelectDropdownDS.styles'

export interface SelectDropdownDSProps extends SelectProps {
  label?: string | false;
  isLoading?: boolean;
  loadingText?: string;
}

export function SelectDropdownDS ({
  label,
  isLoading,
  loadingText,
  placeholder,
  ...props
}: Readonly<SelectDropdownDSProps>) {
  return (
    <>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <Select
        {...props}
        placeholder={isLoading ? loadingText : placeholder}
      />
    </>
  )
}
