import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useUser } from '@/context/UserProvider'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import CancelledBookingModal from '@/components/__Modals/CancelledBookingModal'
import PageWrapper from '@/components/__UI/PageWrapper/PageWrapper'
import { BookingListControls } from '@/components/__BookingComponents'
import { BookingListProvider } from '@/context/BookingListProvider'
import PropTypes from 'prop-types'
import { CallToArmsProvider } from '../../components/__PriceCallToArms/context/CallToArmsProvider'
import CallToArmsModal from '../../components/__PriceCallToArms/CallToArmsModal/CallToArmsModal'
import { useTranslation } from 'react-i18next'
import BookingListTableContainer from '@/components/__BookingComponents/BookingListTableContainer/BookingListTableContainer'

dayjs.extend(advancedFormat)

const BookingListPage = () => {
  const { t } = useTranslation()
  const { hasPermission } = useUser()
  const bookingsPermission = hasPermission('Bookings')

  const [ bookingToCancel, setBookingToCancel ] = useState(null)
  const [ confirmedBookingsToBeCancelled, setConfirmedBookingsToBeCancelled ] = useState([])

  const onConfirm = (data) => {
    if (data !== undefined) {
      setConfirmedBookingsToBeCancelled([...confirmedBookingsToBeCancelled, data])
    }
  }

  if (!bookingsPermission) {
    return (
      <PageWrapper
        title={t('bookingList')}
      >
        <p data-testid="bookingListNoPermissionError">{t('noPermissionToViewBookings')}</p>
      </PageWrapper>
    )
  }

  return (
    <CallToArmsProvider>
      <PageWrapper
        title={t('bookingList')}
      >
        <BookingListProvider>
          {bookingToCancel && (
            <CancelledBookingModal
              data={bookingToCancel}
              onClose={() => setBookingToCancel(null)}
              mustConfirm
              onConfirm={onConfirm}
            />
          )}

          <CallToArmsModal />

          <BookingListControls />

          <BookingListTableContainer
            setBookingToCancel={setBookingToCancel}
            bookingToCancel={bookingToCancel}
            confirmedBookingsToBeCancelled={confirmedBookingsToBeCancelled}
          />
        </BookingListProvider>
      </PageWrapper>
    </CallToArmsProvider>
  )
}

BookingListPage.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func
}

export default BookingListPage
