import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { useQueryUserProperties } from '@/hooks/useQuery'
import { useTranslation } from 'react-i18next'

import { SelectDropdownDS, SelectDropdownDSProps } from '@/components/__Inputs/SelectDropdown'

export interface PropertyDropdownDSProps extends Omit<SelectDropdownDSProps, 'label' | 'options' | 'isLoading' | 'loadingText' | 'placeholder'> {
  label?: boolean;
  operationID: string;
  onPropertiesLoaded?: (data: any) => void;
  hideSingleOption?: boolean;
  autoSelectFirst?: boolean;
}

export function PropertyDropdownDS ({
  operationID,
  label,
  onPropertiesLoaded,
  hideSingleOption,
  autoSelectFirst,
  ...rest
}: Readonly<PropertyDropdownDSProps>) {
  const { data, isLoading } = useQueryUserProperties(operationID)
  const { t } = useTranslation()

  const options = useMemo(() =>
    data?.sites?.flatMap(site =>
      site?.siteProperties).flatMap(property =>
        ({
          text: property.propertyName,
          value: property.propertyId
        })
    ) ?? []
  , [data])

  const hasSingleOption = options?.length === 1
  const isHidden = hideSingleOption && hasSingleOption

  useEffect(() => {
    if (data && onPropertiesLoaded && !isHidden) {
      onPropertiesLoaded(data)
    }
  }, [data, onPropertiesLoaded, isHidden])

  const simulateChangeEvent = useCallback((value: string) => {
    if (rest.onChange && !rest.value) {
      const event = {
        target: {
          name: rest.name,
          value
        }
      }
      rest.onChange(event as ChangeEvent<HTMLSelectElement>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.name, rest.onChange])

  useEffect(() => {
    if (autoSelectFirst && options.length >= 1) {
      simulateChangeEvent(options[0].value)
    }
  }, [autoSelectFirst, options, simulateChangeEvent])

  if (hideSingleOption) {
    if (isLoading || hasSingleOption) {
      return null
    }
  }

  return (
    <SelectDropdownDS
      {...rest}
      label={label && t('selectAccommodation')}
      isLoading={isLoading}
      loadingText={t('loadingAccommodations')}
      placeholder={t('selectAccommodation')}
      options={[{ options }]}
    />
  )
}
