import React from 'react'
import { Body, SiteROW, UnitROW } from './TableBody.styles'
import { useBookingChartContext } from '../../context/BookingChartProvider'
import { useBookingChartDatesContext } from '../../context/BookingChartDatesProvider'
import { useBookingChartBookingsContext } from '../../context/Bookings/BookingChartBookingsProvider'
import UnitGridRowData from '../table_UnitGridRow_data/UnitGridRowData'
import SiteGridRowData from '../table_SiteGridRow_data/SiteGridRowData'

const TableBody = () => {
  const { numberOfDays, startDate } = useBookingChartDatesContext()
  const { bookingsByUnit, unitAvailability } = useBookingChartBookingsContext()
  const { filteredSite, permissions } = useBookingChartContext()

  return (
    <Body>
      {filteredSite && filteredSite.siteProperties && filteredSite.siteProperties.map(property => {
        return (
          <React.Fragment key={property.propertyId}>
            <SiteROW>
              <SiteGridRowData
                numberOfCells={numberOfDays}
                propertyId={property.propertyId}
                startDate={startDate}
              />
            </SiteROW>
            {property.propertyUnits.map(propertyUnit => {
              return (
                <React.Fragment key={propertyUnit.unitId}>
                  <UnitROW>
                    <UnitGridRowData
                      startDate={startDate}
                      numberOfCells={numberOfDays}
                      unitId={propertyUnit.unitId}
                      unitBookings={bookingsByUnit[propertyUnit.unitId] || []}
                      unitCode={propertyUnit.unitCode}
                      propertyId={property.propertyId}
                      propertyName={property.propertyName}
                      unitAvailability={unitAvailability[propertyUnit.unitId] !== undefined ? unitAvailability[propertyUnit.unitId] : Array(numberOfDays).fill(0)}
                      permissions={permissions}
                    />
                  </UnitROW>

                </React.Fragment>
              )
            })}
          </React.Fragment>
        )
      }
      )}
    </Body>
  )
}

export default TableBody
