import React from 'react'
import PropTypes, { array } from 'prop-types'
import Table from '@/components/Table'
import { Season, MobileItem, MobileItemContent, MobileItemCollapse, MobileItemCollapseRow, Chevron } from './PriceRecommendationItem.styles'
import useWindowSize from '@/hooks/useWindowSize'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const PriceRecommendationTableItem = ({ itemData, columnHeaders }) => {
  const { t } = useTranslation()

  const { width } = useWindowSize()
  const isDesktop = width > 599
  const [ isActive, setIsActive ] = React.useState(false)

  const formatWeekCommencingDate = (dateString) => {
    const date = dayjs(dateString)
    return date.format('MMM DD')
  }

  const tableCells = itemData?.data?.map((value, index) => (
    <Table.Cell key={index} data-testid={'prTableItem'} >{isDesktop ? parseFloat(value).toFixed(2) : value}</Table.Cell>
  ))

  const mobileCells = itemData?.data?.filter((_, index) => index === 1 || index === 3).map((value, index) => (
    <p key={index} className={index === 1 ? 'mobile-item-income recommendedPriceContent' : 'mobile-item-income'}>
      {value}
    </p>
  ))

  const isSingleChar = itemData?.season.length === 1

  const getColumnText = (index) => (
    t((columnHeaders?.[index]?.prefixText ?? '') + (columnHeaders?.[index]?.suffixText ?? ''), { year: columnHeaders?.[index]?.yearText })
  )

  return (
    isDesktop
      ? <Table.Row data-testid="prTableRow">
        {itemData?.weekNumber !== 0
          ? <Table.Cell data-testid={'prTableItem'}>
            {t('week')} {itemData?.weekNumber}
            <span style={{ margin: '0 0.2rem' }}></span>
          ({formatWeekCommencingDate(itemData?.isoStartWeekDate)} - {formatWeekCommencingDate(itemData?.isoEndWeekDate)})
          </Table.Cell>
          : <Table.Cell data-testid={'prTableItem'}>
            {formatWeekCommencingDate(itemData?.isoStartWeekDate)}
          </Table.Cell>
        }
        <Table.Cell data-testid={'prTableItem'}>
          <Season
            className={itemData?.season.toLowerCase().replace(/\W/g, '-')} data-testid='season'>
            {itemData?.season}
          </Season>
        </Table.Cell>
        {tableCells}
      </Table.Row>
      : <MobileItem data-testid="mob-item" onClick={() => setIsActive(prev => !prev)}>
        <MobileItemContent isActive={isActive}>
          {itemData?.weekNumber !== 0 ? (
            <p className="mobile-item-date" >
              <span >{t('week')}{itemData?.weekNumber}</span><br />
              <span>({formatWeekCommencingDate(itemData?.isoStartWeekDate)}</span>-<br />
              <span>{formatWeekCommencingDate(itemData?.isoEndWeekDate)})</span>
            </p>
          ) : (
            <p className="mobile-item-date" >
              <span>{formatWeekCommencingDate(itemData.isoStartWeekDate)}</span>
            </p>
          )}
          {mobileCells}
          <Chevron isActive={isActive} />
        </MobileItemContent>
        <MobileItemCollapse isOpen={isActive} data-testid='collapsedRow'>
          <MobileItemCollapseRow className="season-row">
            <span>{t(`${columnHeaders && columnHeaders[1]?.prefixText}`)}:</span>
            <Season
              isSingleChar={isSingleChar}
              className={itemData.season.toLowerCase().replace(/\W/g, '-')}>
              {itemData.season}
            </Season>
          </MobileItemCollapseRow>

          <MobileItemCollapseRow>
            <span>{getColumnText(2)}</span>
            <p>{`${parseFloat(itemData.data[0]).toFixed(2)}`}</p>
          </MobileItemCollapseRow>
          <MobileItemCollapseRow>
            <span>{getColumnText(3)}</span>
            <p>{`${parseFloat(itemData.data[1]).toFixed(2)}`}</p>
          </MobileItemCollapseRow>
          <MobileItemCollapseRow>
            <span>{getColumnText(4)}</span>
            <p>{`${parseFloat(itemData.data[2]).toFixed(2)}`}</p>
          </MobileItemCollapseRow>
          <MobileItemCollapseRow>
            <span>{getColumnText(5)}</span>
            <p className='recommendedPriceRow'>{`${parseFloat(itemData.data[3]).toFixed(2)}`}</p>
          </MobileItemCollapseRow>
        </MobileItemCollapse>
      </MobileItem>
  )
}

PriceRecommendationTableItem.propTypes = {
  itemData: PropTypes.object,
  columnHeaders: array
}

export default PriceRecommendationTableItem
