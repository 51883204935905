import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

type UserMessage = {
  messageId: string;
  messageType: string;
  variables?: {
    year?: number;
    propertyId?: string;
  };
}

type UserMessages = UserMessage[];

export const queryKeyUserMessages = ['user-messages']

export function useQueryUserMessages (options?: UseQueryOptions<UserMessages>) {
  const url = 'v1/users/messages'

  const queryFn = () => apiFetch(url)

  return useQuery<UserMessages>(queryKeyUserMessages, queryFn, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options
  })
}
