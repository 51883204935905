import styled from 'styled-components'
import BookingDetails from '@/components/__BookingComponents/BookingDetails'
import { ItemsContainer } from '@/components/__BookingComponents/BookingDetails/BookingDetails.styles'
import { Text } from '@awaze/design-system.components.text'

export const BookingDetailsStyled = styled(BookingDetails)`
    ${ItemsContainer} {
        --item-column-size: 12rem;
    }
    margin-bottom: 1.5rem;
`
export const StyledText = styled(Text)`
     margin-left: 1.75rem
     `

export const StyledIconAndHeader = styled.div`
    display: inline-flex;
    align-items: center;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
    flex-direction: column;
    align-items: center; 
    }
`

export const TextWithMargin = styled.span`
    margin-left: 1rem;
    font-size: 1.5rem; 

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin-left: 0;
    margin-top: 1rem;
    font-size: 20px;
    margin-bottom: 0.75rem
    }
`

export const FlexWrap = styled.div`
    display: flex ;
    gap: 0.5rem;
 
`

export const ErrorMessage = styled.div`
    background-color: ${({theme}) => theme.colors.bookingModalBackgroundError};
    padding: 1rem;
    color: ${({theme}) => theme.colors.white};
    line-height: 1.8em;
    border-radius: 3px;

  b {
    font-weight: bold;
  }
`
export const ButtonContent = styled.div`
    margin-top: 1.5rem;
`
export const OvalLoaderContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`
