import React, {useState, useEffect} from 'react'
import { AcceptRejectTable, SubmitButton, ApprovalMessage } from './AcceptanceTable.styles'
import PropTypes from 'prop-types'
import AcceptanceModal from '../AcceptanceModal/AcceptanceModal'
import useWindowSize from '@/hooks/useWindowSize'
import { useTranslation } from 'react-i18next'
import useUserBrand from '@/hooks/useUserBrand'

const AcceptanceTable = ({filteredProperties}) => {
  const { t } = useTranslation()
  const { isNovasol } = useUserBrand()

  const [filteredWithAccepted, setFilteredWithAccepted] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const { width } = useWindowSize()
  const isDesktop = width > 1025

  useEffect(() => {
    return () => {
      setFilteredWithAccepted([])
    }
  }, [])

  const onCheckHandler = (i, isChecked) => {
    let items
    /* istanbul ignore if */
    if (filteredWithAccepted && filteredWithAccepted.length) {
      items = [...filteredWithAccepted]
    } else {
      items = [...filteredProperties]
    }
    const item = {...items[i]}
    item.isAccepted = isChecked
    items[i] = item
    setFilteredWithAccepted(items)
  }

  const propertiesToAccept = () => {
    const propertyItems = filteredProperties.map((item, index) => {
      return (
        <tr key={index}>
          <td><label>{item.propertyName}</label></td>
          <td className='radioBtn'>
            <form>
              <div className={isDesktop ? 'accept' : 'acceptIpad'}>
                <input
                  data-testid="accept"
                  type="radio"
                  name={'recommended' + index}
                  value="accept"
                  defaultChecked={item.isAccepted}
                  onChange={
                    /* istanbul ignore next */
                    e => onCheckHandler(index, true)
                  }
                />
              </div>
              <div className={isDesktop ? 'reject' : 'rejectIpad'}>
                <input
                  data-testid="reject"
                  type="radio"
                  name={'recommended' + index}
                  value="reject"
                  onChange={e => onCheckHandler(index, false)}
                />
              </div>
            </form>
          </td>
        </tr>
      )
    })
    return propertyItems
  }

  return (
    <>
      <AcceptRejectTable>
        <thead>
          <tr>
            <th>{t('accommodation')}</th>
            {
              isDesktop
                ? <th>
                  <div className='acceptRejectHeaderContainer'>
                    <div className='optionHeaderAcceptDesktop'>{t('accept')} - {t('recommended')}</div>
                    <div className='optionHeaderRejectDesktop'>{t('reject')}</div>
                  </div>
                </th>
                : <th>
                  <div className='acceptRejectHeaderContainer'>
                    <div className='optionHeaderAccept'>{t('accept')}</div>
                    <div className='optionHeaderReject'>{t('reject')}</div>
                  </div>
                </th>
            }

          </tr>
        </thead>
        <tbody>{propertiesToAccept()}</tbody>
      </AcceptRejectTable>

      <ApprovalMessage>
        <p>{isNovasol ? t('priceFooterTextDefault4') : t('priceFooterTextStart4')}</p>
        <SubmitButton
          data-testid="submitPriceRecButton"
          type="button"
          onClick={() => setModalOpen(true)}>
          {t('submit')}
        </SubmitButton>

        <AcceptanceModal modalOpen={modalOpen} setModalOpen={setModalOpen} filteredProperties={filteredWithAccepted && filteredWithAccepted.length ? filteredWithAccepted : filteredProperties}/>

      </ApprovalMessage>
    </>
  )
}

AcceptanceTable.propTypes = {
  filteredProperties: PropTypes.array
}

export default AcceptanceTable
