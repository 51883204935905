import React from 'react'
import { Discount, StyledContainer, Partner, PartnerHeader } from './BenefitsPage.styles'

import keysafeLogo from '@/assets/logos/Partners/keysafe.png'
import cottagesLogo from '@/assets/logos/Partners/cottages.png'
import goodmannashLogo from '@/assets/logos/Partners/goodmannash.png'
import welshhottubLogo from '@/assets/logos/Partners/welshhottubs.png'
import dhcaccountingLogo from '@/assets/logos/Partners/dhcaccounting.png'
import staycationLogo from '@/assets/logos/Partners/staycationcreation.png'
import PageWrapper from '../../components/__UI/PageWrapper/PageWrapper'
import visionlogo from '@/assets/logos/Partners/vision.png'

const BenefitsPage = () => {
  return (
    <PageWrapper
      title='Partnership plus'
    >
      <StyledContainer>
        <p>
          We are here to help you maximise the bookings and income from your holiday property.
          Often, simply adding a few extra facilities or upgrading those you already offer can
          make a huge difference in widening appeal to potential customers.
        </p>

        <p>
          We are proud to have forged successful partnerships with trusted businesses offering
          products and services that complement ours. You can be confident that the following
          exclusive discounts and offers have been carefully researched and chosen with value for
          money and quality of service in mind.
        </p>

        <Partner>
          <PartnerHeader>
            <h2>Vision Linens - 10% discount off all Vision V products</h2>
            <img src={visionlogo} alt="cottages.com"/>
          </PartnerHeader>
          <p>A world-leading textile company since 1866, Vision Linens create the very best bed, bath and table linen, all of which are ethically sourced and benefit from a long line of quality assurance checks.</p>
          <p>Whether you’re looking for a classic tablecloth, bedding created to give that five-star feel at home or some super soft, cosy bath towels perfect for creating a spa-style sanctuary, you’ll find it all on the online store.</p>
          <p>Visit <a href="https://www.visionlinens.com/vision-v/vision-v-bedroom" target="_blank" rel="noopener noreferrer">www.visionlinens.com/vision-v/vision-v-bedroom</a>, to start shopping now and enjoy an exclusive 10% discount on your purchases by adding the code COT-VV at checkout.</p>
        </Partner>

        <Partner>
          <PartnerHeader>
            <h2>StayCation Creation</h2>
            <img src={staycationLogo} alt="Staycation Creation" />
          </PartnerHeader>
          <p>
              StayCation Creation aims to give clients information on the whole property purchase upfront before going to market to look at shortlists,
              so that they can be aware how much everything is likely to cost prior to committing to anything.
              The outcome will help select the right properties in the right areas for the right price,
              whilst reducing costs on mortgages, renovations, decorating, fitting out and designing the property.
              They also help clients understand to a high degree of accuracy how much the property is likely to make once completed.
              Their services include:
          </p>
          <ul>
            <li>Property search and selection.</li>
            <li>Mortgage discussions, funding options and mortgage broker introductions.</li>
            <li>Project management of any works required to prepare the property for holiday letting.</li>
            <li>Interior design consultancy.</li>
            <li>Advice on maximising the profitability of your holiday let.</li>
          </ul>

          <p>
              For more information, visit <a href="https://staycationcreation.co.uk/" target="_blank" rel="noopener noreferrer">https://staycationcreation.co.uk/</a> or speak to John Hutton on <a href="tel:02080031077">0208 0031077</a>.
          </p>
        </Partner>

        <Partner>
          <PartnerHeader>
            <h2>Goodman Nash</h2>
            <img src={goodmannashLogo} alt="Goodman Nash" />
          </PartnerHeader>
          <p>
              As the UK’s largest dedicated Business Rates and Property Audit Company,
              every year Goodman Nash trigger many millions of pounds in savings for UK businesses.
          </p>
          <p>
              Life is busy enough when you are running a holiday let business, so no doubt you pay your bills and move on,
              but reducing your business rates or council tax is actually a real possibility. Whether you find your rates going up or down,
              or they just remain the same, the fact is that many owners could still be paying too much.
          </p>
          <p>
              Goodman Nash’s targeted approach means they help cut costs for 9 out of every 10 clients who sign up.
              To find out more about, visit <a href="https://www.goodmannash.co.uk" target="_blank" rel="noopener noreferrer">www.goodmannash.co.uk</a> or speak to a Goodman Nash holiday let specialist on <a href="tel:01380 737514">01380 737514</a>.
          </p>
        </Partner>

        <Partner>
          <PartnerHeader>
            <h2>Welsh Hot Tubs</h2>
            <img src={welshhottubLogo} alt="Welsh Hot Tubs" />
          </PartnerHeader>
          <p>Welsh Hot Tubs offers the biggest range of high quality Hot Tubs and Swim Spas in Wales.</p>
          <ul>
            <li>Exclusive discounts and deals for cottages.com clients.</li>
            <li>Over 20 years of technical expertise in-house.</li>
            <li>Dedicated service team to assist with your requirements.</li>
            <li>Large stock of parts and chemicals available.</li>
            <li>We supply, install and service Hot Tubs and Swim Spas all over Wales and the Borders.</li>
          </ul>
          <p>
              To arrange an appointment to come and see us please call 01974 241642 or visit our website <a href="https://www.welshhottubs.co.uk" target="_blank" rel="noopener noreferrer">www.welshhottubs.co.uk</a> for more details.
          </p>
        </Partner>

        <Partner>
          <PartnerHeader>
            <h2>Key Safe - <Discount>10% discount</Discount></h2>
            <img src={keysafeLogo} alt="key Safe" />
          </PartnerHeader>
          <p>Save 10% on the C500 model – the only mechanical Police Accredited key safe device in the UK.</p>
          <ul>
            <li>Rigorously tested – as secure as a domestic front door</li>
            <li>A great solution if your guests arrive late or you cannot be there to greet them on arrival</li>
            <li>Will not compromise or prejudice any claim for theft from your holiday property</li>
            <li>Installation service available</li>
          </ul>
          <p>
              To purchase and claim your discount, visit <a href="https://www.keysafe.co.uk" target="_blank" rel="noopener noreferrer">www.keysafe.co.uk</a> and
              enter the discount code {`"WynVR500"`} on checkout.
          </p>
        </Partner>

        <Partner>
          <PartnerHeader>
            <h2>cottages.com</h2>
            <img src={cottagesLogo} alt="cottages.com" />
          </PartnerHeader>
          <p>We are home to some of the nation’s biggest and most trusted cottage rental brands and we’re delighted to offer our owners a 10% discount on selected breaks* throughout the UK.</p>
          <p>Simply search for your ideal holiday home on <a href="https://www.cottages.com" target="_blank" rel="noopener noreferrer">www.cottages.com</a>, make a note of the property reference and then call 0345 268 1900 and quote OWNERD to make your booking.</p>
          <p className="terms">Terms and Conditions: *Excludes properties in France and Italy. Selected properties may be excluded from the offer.</p>
        </Partner>

        <Partner>
          <PartnerHeader>
            <h2>DHC Accounting</h2>
            <img src={dhcaccountingLogo} alt="DHC Accounting"/>
          </PartnerHeader>
          <p>
            DHC are leading tax and accounting experts in the holiday letting field. They offer accountancy and business consultancy services to a range of businesses and second home owners like you.
          </p>
          <p>
            The company has been advising clients in the holiday letting industry for many years and are experts in the taxation rules that apply, including recent changes to the Inland Revenue’s Furnished Holiday Lettings regulations.
          </p>
          <p>
            DHC offers owners unlimited access to an expert team of advisers. This means that you can call them on 01900 64464 at any time knowing that you will never be charged any more for their time.
            What’s more if any extra work that arises from our free initial consultation, they will give you a fixed price quotation for the extra work before it is started.
          </p>
          <p>We have worked with DHC to negotiate great rates and a fixed fee structure for its owners. The current level of fees is as follows:</p>
          <ul>
            <li>One year’s accounts, tax calculations and standard Land and Property Schedule £420.00</li>
            <li>Extra cottages (each) £60.00</li>
            <li>Tax return (each) £126.00</li>
            <li>General advisory service Free</li>
            <li>Specific detailed tax planning services and advice p.o.a.</li>
          </ul>
          <p>Prices include VAT and DHC is happy to receive payment on completion of work.</p>
          <p>
            Please note: Fees are fixed and based on the assumption that all relevant information is provided at the same time
            so they are able to help you cost effectively. In a small number of cases, e.g. if your tax returns are complicated
            or in the unlikely event of an Inland Revenue enquiry, an additional fee and expenses will be agreed with you in advance.
          </p>
        </Partner>

      </StyledContainer>
    </PageWrapper>
  )
}

export default BenefitsPage
