import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '../helpers'
import { getLocale } from '@/helpers/locale'

type Language = 'en' | 'da' | 'de' | 'es' | 'fr' | 'it' | 'nl' | 'no' | 'pl' | 'sv' | 'hr';

type ReviewTranslationSearchInput = {
  propertyCode: string;
  reviewId: string;
  targetLanguage?: Language;
};

type TranslatedReviewResponse = {
  id: string;
  language: string;
  review: string;
};

function getTranslationSearchURL (parameters: ReviewTranslationSearchInput): string {
  const language = parameters.targetLanguage || getLocale()
  const url = `v1/properties/${parameters.propertyCode}/customerreviews/${parameters.reviewId}`
  const searchParams = new URLSearchParams({ language })
  return `${url}?${searchParams.toString()}`
}

export const queryKeyReviewsTranslation = (input: ReviewTranslationSearchInput) => ['reviewsTranslation', input]

export const queryFnReviewsTranslation = (input: ReviewTranslationSearchInput) => {
  const url = getTranslationSearchURL(input)
  return apiFetch(url)
}

export function useQueryReviewsTranslation (input: ReviewTranslationSearchInput, options?: UseQueryOptions<TranslatedReviewResponse>) {
  const queryKey = queryKeyReviewsTranslation(input)
  const queryFn = () => queryFnReviewsTranslation(input)

  return useQuery<TranslatedReviewResponse>(queryKey, queryFn, {
    staleTime: 4000,
    cacheTime: 0,
    retry: 0,
    refetchOnWindowFocus: false,
    ...options,
    enabled: Boolean(input.propertyCode && input.reviewId) && options?.enabled
  })
}
