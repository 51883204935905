import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import {
  PropertyName,
  PropertyId,
  BookingType,
  ExcessCharges,
  ErrorMessage
} from '../bookingModals.styles'
import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import { Divider, Tooltip, Spinner } from '@/components/__UI'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Flex, Box } from 'reflexbox/styled-components'
import { DATE_FORMAT_DEFAULT } from '@/constants/dates'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { FormikField } from '@/components/__Inputs'
import PassengerForm from '@/components/__Forms/PassengerForm'
import {
  BookingSection,
  Section,
  SectionData,
  SectionLabelUnit,
  SectionTitle,
  BookingForm,
  BookingModal
} from './NewBookingModal.styles'
import { BOOKING_MAX_DURATION, CONSOLIDATED_BOOKING_STATE_OWNER } from '@/constants/bookings'
import { SelectDropdownDS } from '@/components/__Inputs/SelectDropdown'
import { Button } from '@awaze/design-system.components.button'
import { useMutationCreateOwnerBooking } from '@/hooks/useQuery/mutations/useMutationCreateBooking'
import { useQueryBookingQuote } from '@/hooks/useQuery'
import { useTranslation } from 'react-i18next'
import QuoteSectionComponent from './Components/Quote'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const NewBookingModal = ({
  bookings,
  closures,
  onClose,
  onSuccess,
  property,
  selectedDate,
  managedProperty,
  allowPassengers,
  unitCode,
  unitId,
  hasChartPermissions
}) => {
  const { t } = useTranslation()
  const bookingTypeOptions = [
    { text: t('noCleaning'), value: 'false' },
    { text: t('withCleaning'), value: 'true' }
  ]

  const VALIDATION_SCHEMA = Yup.object({
    bookingTypeSelect: Yup.string().required(t('pleaseSelectOption')),
    durationSelect: Yup.string().nullable().required(t('selectDuration')),
    passengerToggle: Yup.bool(),
    titleSelect: Yup.string().when('passengerToggle', {
      is: true,
      then: Yup.string().required(t('titleForPassengerRequired'))
    }),
    forename: Yup.string().when('passengerToggle', {
      is: true,
      then: Yup.string().required(t('forenameForPassengerRequired'))
    }),
    surname: Yup.string().when('passengerToggle', {
      is: true,
      then: Yup.string().required(t('surnameForPassengerRequired'))
    })
  })

  const [duration, setDuration] = useState(0)
  const [chosenDays, setChosenDays] = useState(false)
  const [withClean, setWithClean] = useState(bookingTypeOptions[0].value)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExcessCharges, setIsLoadingExcessCharges] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [holidayPrice, setHolidayPrice] = useState(null)
  const [hasHolidayPrice, setHasHolidayPrice] = useState(null)
  const [extrasObj, setExtrasObj] = useState([])

  const { data: bookingQuoteData, isLoading: isLoadingBookingQuote, error: errorBookingQuote } = useQueryBookingQuote({
    propertyId: property ? property.propertyId : null,
    fromDate: dayjs(selectedDate).format(DATE_FORMAT_DEFAULT),
    toDate: dayjs(selectedDate).add(duration, 'day').format(DATE_FORMAT_DEFAULT),
    unitId: unitId},
  {
    enabled: !!(duration && property && selectedDate)
  })

  const { mutate: createOwnerBooking } = useMutationCreateOwnerBooking({
    onSuccess: (data) => {
      onSuccess && onSuccess({...data})
      onClose && onClose()
    },
    onError: (error) => {
      onClose && onClose()
      toast.error(t('bookingFailed'), null, error)
    },
    onSettled: () => {
      setIsLoading(false)
    }
  })

  const hasClash = (date, items) =>
    items.find(({ startDate }) => dayjs(startDate).isSame(date, 'day'))

  const findNextBooking = () => {
    for (let i = 0; i < BOOKING_MAX_DURATION; i++) {
      const searchForDate = dayjs(selectedDate).add(i + 1, 'day')

      if (closures && closures.length > 0) {
        if (hasClash(searchForDate, closures)) {
          return i
        }
      }

      if (hasClash(searchForDate, bookings)) {
        return i + 1
      }
    }
    return BOOKING_MAX_DURATION
  }

  const getBookableDays = () => {
    const availableDays = bookings ? findNextBooking() : BOOKING_MAX_DURATION

    const bookableDaysOptions = []

    for (let i = 1; i <= availableDays; i++) {
      const option = {
        value: i,
        text: `${i} ${i > 1 ? t('nights').toLocaleLowerCase() : t('night').toLocaleLowerCase()}`
      }

      bookableDaysOptions.push(option)
    }
    return bookableDaysOptions
  }

  const getExtraQuantity = (extra) => {
    return extra.selectionMode === 'Compulsory' ? 0 : (extra.itemQty || 0)
  }

  const handleSubmit = async (values) => {
    const {bookingTypeSelect, durationSelect, titleSelect, forename, surname, passengerToggle, passengerToggleForExtras} = values
    const hasClean = bookingTypeSelect === 'true'
    const leadPassenger = {}

    setIsLoading(true)

    if (passengerToggle) {
      leadPassenger.title = titleSelect.value
      leadPassenger.forename = forename
      leadPassenger.surname = surname
    }

    const bookingData = {
      startDate: dayjs(selectedDate).format(DATE_FORMAT_DEFAULT),
      endDate: dayjs(selectedDate).add(durationSelect, 'day').format(DATE_FORMAT_DEFAULT),
      requiresCleaning: hasClean,
      unitId: unitId,
      leadPassenger: (allowPassengers && passengerToggle) ? leadPassenger : null,
      extras: passengerToggleForExtras ? extrasObj.map(extra => ({
        id: extra.id,
        amount: extra.amount,
        name: extra.name,
        quantity: getExtraQuantity(extra),
        currencyCode: extra.currencyCode
      })) : null
    }

    createOwnerBooking({ propertyId: property?.propertyId, bookingData })
  }

  const handleOnClose = () => {
    setIsDisabled(true)
    setIsLoading(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (bookingQuoteData) {
      const {
        holidayPrice,
        hasHolidayPrice,
        allowBooking,
        extras
      } = bookingQuoteData

      setExtrasObj(extras)
      setIsDisabled(!allowBooking)
      setHasHolidayPrice(hasHolidayPrice)
      setHolidayPrice(holidayPrice ?? null)
    } else if (errorBookingQuote) {
      setIsDisabled(true)
    }

    setIsLoadingExcessCharges(isLoadingBookingQuote)
  }, [bookingQuoteData, errorBookingQuote, isLoadingBookingQuote])

  const getSumOfExtrasItems = (extras) => {
    if (!extras) return

    return parseFloat(extras.map(extra => extra.amount * (extra.itemQty || 0))
      .reduce((a, b) => a + b, 0)) || parseFloat(0)
  }

  const getTotalAmount = () => {
    return (holidayPrice + getSumOfExtrasItems(extrasObj)).toFixed(2)
  }

  const bookableDays = getBookableDays()
  const excessAmountWidth = bookingQuoteData?.bookingExcessChargeAmount ? [1, 1 / 2] : [1]

  if (isLoading) {
    return (
      <BookingModal open onClose={onClose}>
        <Spinner large/>
      </BookingModal>
    )
  }

  return (
    <BookingModal open onClose={onClose}>
      <BookingForm
        id="NewBookingForm"
        validationSchema={VALIDATION_SCHEMA}
        handleSubmit={handleSubmit}
        submitError={null}
        initialValues={{
          durationSelect: null,
          titleSelect: '',
          passengerToggle: false,
          forename: '',
          surname: '',
          bookingTypeSelect: 'false'
        }}
      >

        <BookingType bookingState={CONSOLIDATED_BOOKING_STATE_OWNER}>{t('ownerBooking')}</BookingType>
        <Flex flexWrap="wrap" mt={3}>
          <Box width={excessAmountWidth} pr={[0, 4]}>
            {property && (
              <>
                <PropertyName>{property.propertyName}</PropertyName>
                <PropertyId>{property.propertyId}</PropertyId>
              </>
            )}
            {
              unitCode && (
                <>
                  <SectionLabelUnit>
                    {t('unit')} : {unitCode}
                  </SectionLabelUnit>
                </>
              )
            }
            <BookingSection>
              <Section>
                <SectionTitle>{t('arrival')}:</SectionTitle>
                <SectionData>
                  {dayjs(selectedDate).format('dddd, D MMMM YYYY')}
                </SectionData>
              </Section>
              {bookableDays.length >= 2 && (<>
                {managedProperty && (
                  <Section>
                    <SectionTitle>{t('bookingType')}: <Tooltip text={t('cleaningRate')} posOverride='left' /></SectionTitle>
                    <SectionData>
                      <FormikField
                        component={SelectDropdownDS}
                        inputType="select"
                        name='bookingTypeSelect'
                        options={[{options: bookingTypeOptions}]}
                        value={withClean}
                        onChange={(val) => {
                          setWithClean(val.target.value)
                        }}
                        placeholder={t('selectType')}
                        isOptionDisabled={option => option.disabled}
                        isDesignSystemComponent
                      />
                    </SectionData>
                  </Section>
                )}

                <Section>
                  <SectionTitle>{t('duration')}:</SectionTitle>
                  <SectionData>
                    <FormikField
                      component={SelectDropdownDS}
                      inputType="select"
                      name='durationSelect'
                      options={[{options: bookableDays.slice(1)}]}
                      onChange={e => {
                        setIsLoadingExcessCharges(true)
                        setIsDisabled(!e.target.value)
                        setDuration(e.target.value)
                        setChosenDays(true)
                      }}
                      placeholder={t('selectDuration')}
                      isOptionDisabled={option => option.disabled}
                      isDesignSystemComponent
                    />
                  </SectionData>
                </Section>

                {allowPassengers &&
                <PassengerForm
                  extrasObj = {extrasObj}
                  setExtrasObj = {setExtrasObj}
                  chosenDays = {chosenDays}
                  hasHolidayPrice = {hasHolidayPrice}
                  duration={duration}
                />}

              </>)}
            </BookingSection>
          </Box>
          {bookingQuoteData?.hasBookingExcessCharge && (
            <Box width={[1, 1 / 2]} pl={[0, 4]}>
              <ExcessCharges>
                <h4>{t('bookingAllowanceExceeded')}</h4>
                <p> {t('excessCharge', { currencyAmount: `${bookingQuoteData.bookingExcessChargeAmount.toFixed(2)} ${bookingQuoteData.currency}` })}</p>

              </ExcessCharges>
            </Box>
          )}
        </Flex>
        {bookableDays.length < 2 && (
          <ErrorMessage data-testid="dateNotAvailableError">
            <p>{t('dayUnavailableForBooking')}</p>
          </ErrorMessage>
        )}

        {errorBookingQuote &&
        <ErrorMessage>
          <p>{t('newBookingError')}</p>
        </ErrorMessage>
        }

        <Divider size={4} />

        {hasChartPermissions && duration !== 0 &&
          <QuoteSectionComponent
            quoteDetails={{
              currency: bookingQuoteData?.currency,
              holidayPrice: parseFloat(holidayPrice),
              extrasTotal: parseFloat(getSumOfExtrasItems(extrasObj)),
              total: parseFloat(getTotalAmount())
            }}
            isDisabled={isDisabled && !hasHolidayPrice}
            isLoading={isLoadingBookingQuote}
            isError={errorBookingQuote}
          />
        }

        <Flex alignSelf="flex-end" flexWrap="wrap" width="100%" >
          <Box width={[1 / 2]} pr={[2]}>
            <Button
              data-testid='newBookingModalCancelButton'
              onClick={handleOnClose}
              isFullWidth
              text={t('cancel')}
              variant="outline"
              colourScheme='brand-primary'
            />

          </Box>
          <Box width={[1 / 2]} pl={[2]}>
            <Button
              data-testid='newBookingModalBookButton'
              id='newBookingModalBookButton'
              isFullWidth
              type="submit"
              form="NewBookingForm"
              isDisabled={isDisabled || isLoadingExcessCharges}
              text={t('book')}
              colourScheme='brand-primary'
            >
              <Spinner large/>
            </Button>

          </Box>
        </Flex>
      </BookingForm>
    </BookingModal>
  )
}

NewBookingModal.propTypes = {
  bookings: PropTypes.array,
  closures: PropTypes.array,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  property: PropTypes.object,
  selectedDate: PropTypes.object,
  managedProperty: PropTypes.bool,
  hasChartPermissions: PropTypes.bool,
  allowPassengers: PropTypes.bool,
  unitCode: PropTypes.string,
  unitId: PropTypes.string
}

export default NewBookingModal
