export const getMessageBannerClickUrl = (messageType, propertyId, year) => { 
  switch (messageType) {
    case 'newPriceRecommendations':
      return '/pricing-list/price-recommendations'
    case 'newRenewal':
      return `/rental/${propertyId}/renewal/${year}/contract`
    default:
      return '/'
  }
}
