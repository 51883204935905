import { deleteCookie } from '@/helpers/cookies'
import { UserAuthorisations } from '@/hooks/useQuery'
import { createContext, useContext } from 'react'

type UserContextType = {
  user: null | {
    attributes: {
      email: string;
      brand: string;
      productMarkets: string[];
    };
  };
  loading: boolean;
  refetching: boolean;

  permissions: UserAuthorisations[];
  hasPermission: (permission: string) => boolean;

  login: () => void;
  logout: () => void;
  isLoggingOut: boolean;
}
const UserContext = createContext<UserContextType | null>(null)

const useUser = () => {
  const context = useContext(UserContext)
  if (context == null) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component')
  }

  return context
}

const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

const triggerLogout = () => {
  const cookiesToDelete = ['tokenExpires', 'idToken', 'accessToken', 'refreshToken', 'LastAuthUser', 'userId']
  cookiesToDelete.forEach(element => deleteCookie(element, '/'))
  localStorage.removeItem('lastClickedLink')
  sessionStorage.removeItem('messageShownOnce')
}

function trimAndLowerCase (value) {
  return value
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word, index) => (index === 0 ? word.charAt(0).toLowerCase() + word.slice(1) : word.charAt(0).toUpperCase() + word.slice(1)))
    .join('')
}

function hasPermission (value, permissions) {
  const modifiedValue = trimAndLowerCase(value)
  if (modifiedValue === 'none') return true
  return permissions?.length > 0 && !!permissions.find(({ name, authorised }) =>
    trimAndLowerCase(name) === modifiedValue && authorised
  )
}

export { UserContext, useUser, parseJwt, triggerLogout, hasPermission }
