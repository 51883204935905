import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { apiFetch } from '../helpers'

interface User {
    id: string;
    guid: string;
    username: string;
    brand: 'awaze' | 'novasol';
    productMarkets?: string[];
}

export const queryKeyUser = ['user']

export function fetchUser (): Promise<User> {
  return apiFetch('v1/user')
}

export function useQueryUser (options?: UseQueryOptions<User>) {
  return useQuery({
    queryKey: queryKeyUser,
    queryFn: fetchUser,
    refetchOnWindowFocus: false,
    ...options
  })
}
