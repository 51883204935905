import React from 'react'
import reset from 'styled-reset'
import globalTheme from './Theme'
import { space } from 'styled-system'
import { darken } from 'polished'
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import WebFont from 'webfontloader'

export default createGlobalStyle`
  ${reset};

  html, body {
    box-sizing: border-box;
    /* font-size: 16px; should be 62.5%; if we want to use 1rem=10px */
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'DM Sans', sans-serif;

    ${() => {
    WebFont.load({
      google: {
        families: ['Poppins:400,400i,500,600,600i,700', 'DM Sans:400,400i,500,500i,600,600i,700,700i', 'sans-serif']
      }
    })
  }}
  }

  h1 {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 0.8rem;

    ${globalTheme.fonts.Heading200}
    ${space}
  }

  h2 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875;
    margin-bottom: 0.8rem;

    ${globalTheme.fonts.Heading100}
    ${space}
  }

  h3 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-bottom: 0.8rem;
    ${space}
  }

  h4 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    ${space}
  }

  *,
  &::before,
  &::after {
    box-sizing: inherit;
  }

  body {
    font-size: 1rem;
    line-height: 1.2;
    font-family: DM Sans, sans-serif;
    font-weight: 400;
    color: ${globalTheme.colors.blackTwo};
    background-color: ${globalTheme.colors.bgColor};
  }

  label {
    ${globalTheme.fonts.InputTitle300}
  }
  button {
    color: inherit;
  }
  p {
    strong {
      font-weight: 600;
    }

    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  p, li {
    a {
      color: ${globalTheme.colors.primary};
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }

      &:active {
        font-weight: 600;
      }
    }
  }


  ul {
    list-style-type: none;
    font-size: 0.94rem;

    li {
      strong {
        font-weight: 600;
      }

    }
  }

  .no-print {
    @media print {
      display: none;
    }
  }

  .collapse-css-transition {
    transition: height ${globalTheme.animationSpeed} cubic-bezier(0.6, 0, 0.4, 1);
    overflow: hidden;
    border-spacing:0;
    }

  .react-datepicker-wrapper {
    width: 100%;
  }

  form#SignInForm {
    width:100%;
  }
  
  input[type=text], input[type=password], input[type=email] {
    margin-bottom: 1rem;
  }

  .intro-text {
    p {
      font-size: 1rem;
      margin-bottom: 0.6rem;
      
      ${globalTheme.mediaQueries.phoneOnly} {
        font-size: 0.8rem;
      }
    }
  }
`

export const SpecialIcon = styled(props => (
  <svg {...props} style={{ enableBackground: 'new 0 0 48 48' }} version="1.1" viewBox="0 0 48 48" xmlSpace="preserve">
    <g className="icon-i"><rect height="15" width="6" x="22" y="21"/></g>
    <g className="icon-i"><rect height="5" width="6" x="22" y="10"/></g>
  </svg>
))`
    ${({ theme }) => `
        width: auto;
        height: 1.5rem;
        border-radius: 50%;
        background-color: ${theme.colors.primary};

        .icon-i {
            fill: ${theme.colors.white};
        }
    `}
`

export const CrossButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1.33rem;
  width: 1.125rem;
  height: 1.125rem;
  border: 0;
  cursor: pointer;
  background-color: transparent;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 2px;
    background-color: ${({theme}) => theme.colors.blackTwo};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: ${({theme}) => darken(0.5, theme.colors.blackTwo)};
    }
  }
`

export const LinkButton = styled(Link)`
  padding: 0.3rem 0.75rem;
  border-radius: ${({theme}) => theme.borderRadius};
  height: 2rem;
  color: ${({theme}) => theme.colors.black};
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.colors.primary};
  text-align: center;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  transition: ${({theme}) => `background-color ${theme.animationSpeed} ease, border ${theme.animationSpeed} ease, color ${theme.animationSpeed} ease`};

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    max-width: none;
  }

  &:after {
    content: '';
    position: relative;
    display: inline-block;
    top: 0.4em;
    right: 0;
    width: 0.6em;
    height: 0.6em;
    transform: rotate(45deg);
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    vertical-align: top;
    margin-left: 0.75rem;
    transition: border-color ${({theme}) => theme.animationSpeed} ease;
  }

  &:hover {
    color: ${({theme}) => theme.colors.white};
    background-color: ${({theme}) => theme.colors.primary};

    &:after {
      border-color: ${({theme}) => theme.colors.white};
    }
  }

  &:active {
    color: ${({theme}) => theme.colors.activeLinkButton};
    border-color: ${({theme}) => theme.colors.activeLinkButton};
  }

  &:disabled {
    cursor: default;
    color: ${({theme}) => theme.colors.disabled};
    border-color: ${({theme}) => theme.colors.disabled};
  }
`
export const FaqStyling = styled.div`
  i {
    font-style: italic;
  }
  
  b {
    font-weight: 600;
  }

  li {
    text-indent: 0px;

    p {
        display: inline-block;
      }
  }
`

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: ${({ space }) => space ? `space-${space}` : 'center'};
  align-items: center;
  width: 100%;
  ${({ mt }) => mt ? `margin-top: ${mt};` : ''}
  ${({ mb }) => mb ? `margin-bottom: ${mb};` : ''}
`
export const Chevron = styled.div`
  position: relative;
  width: ${({width}) => `${width}rem` || '1.5rem'};
  height: ${({height}) => `${height}rem` || '1.5rem'};
  height: 1.5rem;
  margin: 0 auto;
  cursor: pointer;
  transition: transform ${({ theme }) => theme.animationSpeed} ease;  

  ${({ active }) => active ? `transform: rotate(180deg);` : ''}

  &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 1px;
      height: ${({height}) => `${(height * 0.75)}rem` || '1rem'};
      background-color: ${({ theme }) => theme.colors.black};
      transform-origin: bottom;
  }

  &:before {
      transform: rotate(45deg)
  }

  &:after {
      transform: rotate(-45deg)
  }
`
