import React, { useState } from 'react'
import { CollectionCell } from './UnitGridRowCollectionCell.styles'
import { getGridRowStyle, getBookingStateTypeColor, startReservationOnGridStyle, fullReservationOnGridStyle } from '../../shared/ChartFunctions'
import { generateUserFriendlyDateFormat, addDaysToDate } from '@/helpers/dateUtils'
import { hasAuth } from '@/helpers/authorisations'
import MakeBookingModalContainer from '../../makeBookingModalContainer/MakeBookingModalContainer'
import BookingChartModalContainer from '../../bookingChartModalContainer/BookingChartModalContainer'
import PropTypes from 'prop-types'

const UnitGridRowData = ({
  numberOfCells,
  startDate,
  unitBookings,
  propertyId,
  propertyName,
  unitAvailability,
  unitId,
  unitCode,
  permissions
}) => {
  const [parametersForNewBooking, setParametersForNewBooking] = useState()
  const [successfulBookingData, setSuccessfulBookingData] = useState()
  const [bookingByIdParameters, setBookingByIdParameters] = useState()
  const [cancelledBookingId, setCancelledBookingId] = useState(null)
  const [totalHolidayPriceInBookedModal, setTotalHolidayPriceInBookedModal] = useState(null)
  const [cancelledPropertyAndUnit, setCancelledPropertyAndUnit] = useState(null)
  const hasCreateBookingPermission = hasAuth('createBooking', permissions)

  const cellCollection = []
  for (let i = 0; i < numberOfCells; i++) {
    cellCollection.push(i)
  }

  const getCurrentCellDate = (index) => {
    return addDaysToDate(startDate, index)
  }

  const getChartEventOnClickFunction = (bookingId) => {
    return () => {
      setBookingByIdParameters({
        bookingId: bookingId,
        propertyId: propertyId,
        propertyName: propertyName
      })
    }
  }

  const getEndDateWithOffsetForBookingComparison = (theEndDate) => {
    const endDate = new Date(theEndDate)
    endDate.setHours(endDate.getHours() + 12)
    return endDate
  }

  const getConfirmedReservation = (bookings, currentCellDate) => {
    return bookings.filter((b) => {
      return (
        b.reservationState !== 'Cancelled' &&
          new Date(b.startDate) <= currentCellDate &&
          getEndDateWithOffsetForBookingComparison(b.endDate) >= currentCellDate
      )
    })
  }

  const makeNewBookingClick = (index) => {
    setParametersForNewBooking({
      startDate: getCurrentCellDate(index),
      propertyId: propertyId,
      propertyName: propertyName,
      unitId: unitId,
      unitCode: unitCode
    })
  }

  const renderStartOfReservationOnGrid = (booking, index, includeMargin = false) => {
    return (
      <div
        className="startReservation"
        onClick={getChartEventOnClickFunction(booking.bookingId)}
        style={{
          marginTop: `${includeMargin ? '-2.25rem' : ''}`,
          background: `linear-gradient(to top left, ${getBookingStateTypeColor(
            booking.reservationState,
            booking.reservationType
          )} 0 calc(50% - 1px), transparent 50% 100%)`,
          boxShadow: `${startReservationOnGridStyle(index)} ${getBookingStateTypeColor(
            booking.reservationState,
            booking.reservationType
          )}`
        }}
      ></div>
    )
  }

  const renderEndOfReservationOnGrid = (booking, index) => {
    return (
      <div
        className="endReservation"
        onClick={getChartEventOnClickFunction(booking.bookingId)}
        style={{
          background: `linear-gradient(to bottom right, ${getBookingStateTypeColor(
            booking.reservationState,
            booking.reservationType
          )} 0 calc(50% - 1px), transparent 50% 100%)`
        }}
      ></div>
    )
  }

  const renderFullReservationOnGrid = (booking, index) => {
    return (
      <div
        className="fullReservation"
        onClick={getChartEventOnClickFunction(booking.bookingId)}
        style={{
          backgroundColor: getBookingStateTypeColor(
            booking.reservationState,
            booking.reservationType
          ),
          boxShadow: `${fullReservationOnGridStyle(index)} ${getBookingStateTypeColor(
            booking.reservationState,
            booking.reservationType
          )}`
        }}
      ></div>
    )
  }

  const renderFullyClosedBlockOnGrid = (
    closed,
    endOfBookingDayClosed,
    startOfBookingDayClosed,
    fullReservation,
    endPreviousStartNewReservation
  ) => {
    return (
      closed &&
        !endOfBookingDayClosed &&
        !startOfBookingDayClosed &&
        !fullReservation &&
        !endPreviousStartNewReservation && <div className="fullyClosed"></div>
    )
  }

  const getAvailableToBookCellStyling = (endClosed, endReservation) => {
    /* istanbul ignore next */
    if (endClosed) return 'availableToBookWithClosedEnd'
    if (endReservation) return 'availableToBookWithReservationEnd'
    return 'availableToBook'
  }

  const isFullyClosed = (startReservation, endReservation, endClosed, previousDayClosed, index, closed) => {
    return !startReservation && !endReservation && !endClosed && ((previousDayClosed || index === 0) && closed)
  }

  const isStartClosed = (closed, previousDayClosed, index, startReservation, endReservation, fullReservation, endPreviousStartNewReservation) => {
    /* istanbul ignore next */
    return closed && !previousDayClosed && index !== 0 && !startReservation && !endReservation && !fullReservation && !endPreviousStartNewReservation
  }

  const isEndClosed = (previousDayClosed, closed, startReservation, endReservation, fullReservation, endPreviousStartNewReservation) => {
    return previousDayClosed && !closed && !startReservation && !endReservation && !fullReservation && !endPreviousStartNewReservation
  }

  return (
    <React.Fragment>
      {(parametersForNewBooking || successfulBookingData) &&
        <MakeBookingModalContainer
          propertyId={parametersForNewBooking && parametersForNewBooking.propertyId}
          propertyName={parametersForNewBooking && parametersForNewBooking.propertyName}
          startDate={parametersForNewBooking && parametersForNewBooking.startDate}
          unitCode={parametersForNewBooking && parametersForNewBooking.unitCode}
          unitId={parametersForNewBooking && parametersForNewBooking.unitId}
          setParametersForNewBooking= {setParametersForNewBooking}
          successfulBookingData={successfulBookingData}
          setSuccessfulBookingData={setSuccessfulBookingData}
          setTotalHolidayPriceInBookedModal={setTotalHolidayPriceInBookedModal}
          totalHolidayPriceInBookedModal={totalHolidayPriceInBookedModal}
        />
      }
      {
        (bookingByIdParameters || cancelledBookingId) &&
          <BookingChartModalContainer
            bookingId={bookingByIdParameters && bookingByIdParameters.bookingId.toString()}
            propertyId={bookingByIdParameters && bookingByIdParameters.propertyId}
            propertyName={bookingByIdParameters && bookingByIdParameters.propertyName}
            setBookingByIdParameters={setBookingByIdParameters}
            cancelledBookingId={cancelledBookingId}
            setCancelledBookingId={setCancelledBookingId}
            cancelledPropertyAndUnit={cancelledPropertyAndUnit}
            setCancelledPropertyAndUnit={setCancelledPropertyAndUnit}
            totalHolidayPrice={totalHolidayPriceInBookedModal}
          />
      }

      {cellCollection.map((index) => {
        const currentCellDate = getCurrentCellDate(index)
        const confirmedBookings = getConfirmedReservation(
          unitBookings,
          currentCellDate
        )

        const startReservation =
          confirmedBookings.length === 1 &&
          generateUserFriendlyDateFormat(confirmedBookings[0].startDate) ===
            generateUserFriendlyDateFormat(currentCellDate)

        const endReservation =
          confirmedBookings.length === 1 &&
          generateUserFriendlyDateFormat(confirmedBookings[0].endDate) ===
            generateUserFriendlyDateFormat(currentCellDate)

        const fullReservation =
          confirmedBookings.length === 1 && !startReservation && !endReservation

        const endPreviousStartNewReservation = confirmedBookings.length === 2

        const closed = unitAvailability[index] === 0
        const previousDayClosed = unitAvailability[index - 1] === 0
        const endOfBookingDayClosed = closed && endReservation
        const startOfBookingDayClosed = previousDayClosed && startReservation
        const startClosed = isStartClosed(closed, previousDayClosed, index, startReservation, endReservation, fullReservation, endPreviousStartNewReservation)
        const endClosed = isEndClosed(previousDayClosed, closed, startReservation, endReservation, fullReservation, endPreviousStartNewReservation)
        const fullyClosed = isFullyClosed(startReservation, endReservation, endClosed, previousDayClosed, index, closed)

        return (
          <React.Fragment key={index}>
            <CollectionCell
              data-testid='cells'
              style={getGridRowStyle(index, numberOfCells, startDate)}
            >
              <div className="tablePopulation">

                {(startReservation && !endOfBookingDayClosed && !startOfBookingDayClosed) &&
                renderStartOfReservationOnGrid(confirmedBookings[0], index, false)}

                {(endReservation && !endOfBookingDayClosed && !startOfBookingDayClosed) &&
                renderEndOfReservationOnGrid(confirmedBookings[0], index)}

                {endOfBookingDayClosed && (
                  <div className="startClosed">
                    {renderEndOfReservationOnGrid(confirmedBookings[0], index)}
                  </div>
                )}
                {startOfBookingDayClosed && (
                  <div className="endClosed">
                    {renderStartOfReservationOnGrid(confirmedBookings[0], index, false)}
                  </div>
                )}

                {endClosed && (
                  <div className="endClosed" onClick={
                    /* istanbul ignore next */
                    () => { makeNewBookingClick(index) }
                  }>
                  </div>
                )}

                {
                  /* istanbul ignore next */
                  startClosed && (
                    <div className="startClosed"></div>
                  )}

                {fullReservation &&
                renderFullReservationOnGrid(confirmedBookings[0], index)}

                {endPreviousStartNewReservation && (
                  <div className="endPreviousStartNewReservation">
                    {renderEndOfReservationOnGrid(confirmedBookings[0], index)}
                    {renderStartOfReservationOnGrid(confirmedBookings[1], index, true)}
                  </div>
                )}

                {fullyClosed && renderFullyClosedBlockOnGrid(
                  closed,
                  endOfBookingDayClosed,
                  startOfBookingDayClosed,
                  fullReservation,
                  endPreviousStartNewReservation
                )}
                {
                  hasCreateBookingPermission &&
                !closed &&
                !endPreviousStartNewReservation &&
                !fullReservation &&
                !startReservation && (
                    <div
                      className={getAvailableToBookCellStyling(endClosed, endReservation)}
                      onClick={() => { makeNewBookingClick(index) }}>
                    </div>
                  )}
                {
                  !hasCreateBookingPermission &&
                !closed &&
                !endPreviousStartNewReservation &&
                !fullReservation &&
                !startReservation && (
                    <div style={{cursor: 'not-allowed'}}
                      className={getAvailableToBookCellStyling(endClosed, endReservation)}>
                    </div>
                  )}
              </div>
            </CollectionCell>
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

UnitGridRowData.propTypes = {
  numberOfCells: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  unitBookings: PropTypes.array,
  propertyId: PropTypes.string,
  propertyName: PropTypes.string,
  unitAvailability: PropTypes.array,
  unitId: PropTypes.string,
  unitCode: PropTypes.string,
  permissions: PropTypes.array,
  cellCollection: PropTypes.array
}

export default UnitGridRowData
