import styled from 'styled-components'
import Button from '@/components/__Inputs/Button'
import DesignTokens from '@/global/DesignTokens'

export const CancelButton = styled(Button)`
    width: auto;
    height: auto;
    margin: 1rem auto;
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
    background-color: transparent;

    &:hover{
        color: ${DesignTokens.ColorButtonSolidSecondaryBackgroundHover};
        border: 2px solid ${DesignTokens.ColorButtonSolidSecondaryBackgroundHover};
        background: #f9f9f9;
        transition: all 0.4s ease-in-out;
    }


    @media screen and (max-width: 1024px) {
        margin: 0 0 1.5rem 3.5rem;
    }

    ${({theme}) => theme.mediaQueries.phoneOnly} {
        margin: 0 0 1.5rem 3.5rem;
        width: 70%;
    }
`
export const ButtonContent = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        margin-left: 1rem;
        margin-right: 12px;
    }
    p{
        margin-right: 1rem;
    }
`
