import React from 'react'
import {
  Modal,
  ModalBody,
  ModalContent
} from '@awaze/design-system.components.modal'
import { Text } from '@awaze/design-system.components.text'
import { useTranslation } from 'react-i18next'
import { BookingType, InfoContainer, BookingTypeIcon, ModalContentWrapper } from './ToolTipModal.styles'
interface ToolTipModalProps {
  isOpen: boolean;
  close: () => void;
}
const ToolTipModal = ({isOpen, close}: ToolTipModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} close={close}>
      <ModalContent header={t('explanation')}>
        <ModalBody>
          <InfoContainer>
            <BookingType bookingType={'Customer'} />
            <Text as="span" type="bold" data-testid="modalContent">
              {t('guestBooking')}
            </Text>
          </InfoContainer>
          <ModalContentWrapper>
            <span data-testid="modalContent">
              {t('guestBookingConfirmed')}
            </span>
          </ModalContentWrapper>
          <InfoContainer>
            <BookingType bookingType={'Owner'} />
            <Text as="span" type="bold" data-testid="modalContent">
              {t('ownerBooking')}
            </Text>
          </InfoContainer>
          <ModalContentWrapper>
            <span data-testid="modalContent">
              {t('ownerBookingPeriod')}
            </span>
          </ModalContentWrapper>
          <InfoContainer>
            <BookingType bookingState={'Changeover'} />
            <Text as="span" type="bold" data-testid="modalContent">
              {t('changeover')}
            </Text>
          </InfoContainer>
          <ModalContentWrapper>
            <span data-testid="modalContent">
              {t('changeoverExplained')}
            </span>
          </ModalContentWrapper>
          <InfoContainer>
            <BookingType bookingState={'Other'} />
            <Text as="span" type="bold" data-testid="modalContent">
              {t('otherBooking')}
            </Text>
          </InfoContainer>
          <ModalContentWrapper>
            <span data-testid="modalContent">
              {t('otherBookingExplained')}
            </span>
          </ModalContentWrapper>
          <InfoContainer>
            <BookingType bookingType={'Provisional'} />
            <Text as="span" type="bold" data-testid="modalContent">
              {t('provisional')}
            </Text>
          </InfoContainer>
          <ModalContentWrapper>
            <span data-testid="modalContent">
              {t('provisionalBooking')}
            </span>
          </ModalContentWrapper>
          <InfoContainer>
            <BookingTypeIcon bookingType={'Closed'} />
            <Text as="span" type="bold" data-testid="modalContent">
              {t('closed')}
            </Text>
          </InfoContainer>

          <span data-testid="modalContent">
            {t('propertyNotListed')}
          </span>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ToolTipModal
