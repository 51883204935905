import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Spinner } from '@/components/__UI'
import { Button, Form, TextInput, FormikField } from '@/components/__Inputs'
import PermissionsCheckbox from '../../__Inputs/Checkbox_Permissions/PermissionsCheckbox'
import {
  ButtonSection,
  UserInfoSection,
  PermissionsSection,
  SitesSection,
  PermissionItem,
  Title,
  Text,
  TextBold,
  UserTitle,
  UserDetailsContainerAdmin,
  UserTitleAdmin,
  UserInfoAdmin,
  UserInfo,
  UserDetailsContainer,
  AdminTickImg,
  NotAnAdmin
} from './UserAdminEditModal.styles'
import {StyledSelectDropdown} from '../UserAdminModal/UserAdminModal.styles'

import useGraphQl, {
  graphQlFetch,
  DELETE_USER_ADMIN,
  UPDATE_USER_ADMIN,
  GET_USER_ADMIN_DETAILS,
  GET_USER_ADMIN_SITES,
  GET_USER_ADMIN_PERMISSIONS
} from '@/hooks/useGraphQl'

import toast from '@/helpers/toast'
import { selectStyles } from '../../../components/__Inputs/SelectDropdown/SelectDropdown.styles'

import IconAdminUserTick from '@/assets/icons/adminusertick.svg'

const UserAdminEditModal = ({userId, onClose, reloadUsers}) => {
  const [formError, setFormError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isUserConfirmingDelete, setIsUserConfirmingDelete] = useState(false)
  const [confirmEditUserDetails, setConfirmEditUserDetails] = useState(false)
  const [isEditUserSubmitting, setIsEditUserSubmitting] = useState()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [userSites, setUserSites] = useState('')
  const [defaultSites, setDefaultSites] = useState([])
  const [currentSiteOptions, setCurrentSiteOptions] = useState([])
  const [allSiteOptions, setAllSiteOptions] = useState([])
  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [currentPermissionOptions, setCurrentPermissionOptions] = useState([])
  const [temporaryPermissionOptions, setTemporaryPermissionOptions] = useState([])

  const isButtonDisabledforPermissions = () => {
    if (temporaryPermissionOptions.length < currentPermissionOptions.length || temporaryPermissionOptions.length > currentPermissionOptions.length) {
      return false
    }
    // eslint-disable-next-line no-unused-vars
    for (const permission of temporaryPermissionOptions) {
      if (currentPermissionOptions.find(f => f.id === permission.id) === undefined) {
        return false
      }
    }

    return true
  }

  useGraphQl({
    asObject: true,
    query: GET_USER_ADMIN_SITES,
    onSuccess: (data) => {
      if (data && Array.isArray(data)) {
        const options = data.map((r) => {
          return { label: r.nameWithCode, value: r.id }
        })
        if (options) setAllSiteOptions([...options])
      }
    }
  })

  const [allPermissions] = useGraphQl({ query: GET_USER_ADMIN_PERMISSIONS })
  const allPermissionOptions = allPermissions && Array.isArray(allPermissions) ? allPermissions.map(p => ({ label: p.description, value: p.id })) : []

  const [user] = useGraphQl({
    query: GET_USER_ADMIN_DETAILS,
    variables: { userId },
    onSuccess: (data) => {
      const uniquePermissions = [...new Set(data.sitePermissions.map(item => item.id))]
      const permissionOptionsToSet = []
      uniquePermissions.forEach(element => permissionOptionsToSet.push({id: element}))
      setCurrentPermissionOptions(() => permissionOptionsToSet)
      setTemporaryPermissionOptions(() => permissionOptionsToSet)
    }
  })

  const handleEditUser = async () => {
    setIsEditUserSubmitting(true)
    const values = {
      firstName: firstName,
      lastName: lastName,
      jobTitle: jobTitle,
      sites: Array.isArray(userSites)
        ? userSites.map(s => s.value || s)
        : [userSites.value],
      permissions: Array.from(temporaryPermissionOptions, a => a.id)
    }

    const errorMessage = `Failed to edit user, please try again later.`

    await graphQlFetch({
      query: UPDATE_USER_ADMIN,
      variables: { userId, values }
    })
      .then(({ success }) => {
        if (success) {
          toast.success(`User successfully edited.`)
          reloadUsers()
          onClose && onClose()
        } else {
          toast.error(errorMessage)
          setFormError(errorMessage)
        }
      })
      .catch(() => {
        setFormError(errorMessage)
        toast.error(errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
        setIsEditUserSubmitting(false)
      })
  }

  const promptToDeleteUser = () => {
    setIsUserConfirmingDelete(true)
  }

  const resetPromptToDeleteUser = () => {
    setIsUserConfirmingDelete(false)
  }

  const registerTemporarySelection = (permissionValue, permissionStatus) => {
    if (permissionStatus) {
      const permission = allPermissionOptions.find(o => o.value === permissionValue)
      permission && setTemporaryPermissionOptions([{id: permissionValue}, ...temporaryPermissionOptions])
    } else {
      setTemporaryPermissionOptions(temporaryPermissionOptions.filter(o => o.id !== permissionValue))
    }
  }

  const handleDeleteUser = async () => {
    const errorMessage = 'Failed to delete User, please try again later.'
    setIsLoading(true)

    await graphQlFetch({ query: DELETE_USER_ADMIN, variables: { userId } })
      .then((data) => {
        if (data.success) {
          toast.success('User successfully deleted')
          reloadUsers()
        } else {
          toast.error(errorMessage)
        }
        onClose && onClose()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const OnFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }
  const OnLastNameChange = (e) => {
    setLastName(e.target.value)
  }
  const OnJobTitleChange = (e) => {
    setJobTitle(e.target.value)
  }

  useEffect(() => {
    if (user) {
      setIsLoadingUser(false)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setJobTitle(user.jobTitle)
      if (user.sites) {
        setUserSites(user.sites.map((site) => {
          return { label: site.nameWithCode, value: site.id }
        }))
        setDefaultSites(user.sites.map((site) => {
          return { label: site.nameWithCode, value: site.id }
        }))
      }
    }

    return () => {
      setIsEditUserSubmitting(false)
    }
  }, [user])

  useEffect(() => {
    if (allSiteOptions && userSites) {
      const options = allSiteOptions.filter((site) => !userSites.find(us => us.value === site.value))
      if (options) {
        setCurrentSiteOptions([...options])
      }
    } else {
      setCurrentSiteOptions([...allSiteOptions])
    }
  }, [userSites, allSiteOptions])

  if (isLoadingUser) {
    return <Spinner />
  }

  const userInfoForm = () => {
    return (
      <div style={{ overflowX: 'hidden' }}>
        <UserDetailsContainer>
          <UserTitle>Email</UserTitle>
          <UserInfo>{user.email}</UserInfo>
        </UserDetailsContainer>

        <UserDetailsContainerAdmin>
          <UserTitleAdmin>Admin role</UserTitleAdmin>
          <UserInfoAdmin>
            {user.adminRole ? (
              <AdminTickImg alt="Admin" src={IconAdminUserTick} />
            ) : (
              <NotAnAdmin>Not an admin</NotAnAdmin>
            )}
          </UserInfoAdmin>
        </UserDetailsContainerAdmin>
        <Form
          id="UserEditForm"
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle
          }}
          handleSubmit={handleEditUser}
          submitError={formError}
          enableReinitialize
        >
          <UserInfoSection>
            <div className='box1'>
              <TextInput
                name="firstName"
                type="text"
                value={firstName}
                onChange={(e) => OnFirstNameChange(e)}
                label={'First name '}
                placeholder="Edit first name"
              />
            </div>
            <div className='box2'>
              <TextInput
                name="lastName"
                type="text"
                value={lastName}
                onChange={(e) => OnLastNameChange(e)}
                label={'Last name '}
                placeholder="Edit last name"
              />
            </div>
            <div className='box3'>
              <TextInput
                name="jobTitle"
                type="text"
                value={jobTitle}
                onChange={(e) => OnJobTitleChange(e)}
                label={'Job title '}
                placeholder="Edit job title"
              />
            </div>
          </UserInfoSection>
          <SitesSection>
            <FormikField
              component={StyledSelectDropdown}
              isMulti
              inputType="select"
              name="sites"
              label="Sites"
              loading={isLoading}
              options={currentSiteOptions}
              value={userSites}
              isSearchable={true}
              onChange={setUserSites}
              styles={selectStyles}
              noSingleLock={true}
              noItemsText="No matching sites"
              placeholder="Please, choose at least one site..."
            />
          </SitesSection>
          <Title>Permissions </Title>
          <PermissionsSection>
            {allPermissionOptions.map((p, index) => {
              return <PermissionItem key={index}>
                <PermissionsCheckbox
                  name='permissions'
                  label={p.label}
                  disabled={false}
                  right={true}
                  value={p.value}
                  isPermissionsCheckBox={true}
                  isPermissionSet={temporaryPermissionOptions && temporaryPermissionOptions.find(element => element && element.id === p.value)}
                  registerTemporarySelection={registerTemporarySelection}
                />
              </PermissionItem>
            })}
          </PermissionsSection>
          <ButtonSection>
            {
              !confirmEditUserDetails &&
            <><Button error data-testid="deleteUserButton" fullWidth onClick={ promptToDeleteUser }>
                Delete User
            </Button>
            <Button
              id="saveBtn"
              data-testid="saveUserButton"
              primary
              fullWidth
              type="submit"
              isLoading={ isLoading }
              loading={ isEditUserSubmitting }
              disabled={ (jobTitle === '' ||
                  firstName === '' ||
                  lastName === '' ||
                  (JSON.stringify(userSites).replace(/\s/g, '') === JSON.stringify(defaultSites).replace(/\s/g, '') &&
                    user.jobTitle === jobTitle &&
                    user.firstName === firstName &&
                    user.lastName === lastName
                  ) || !userSites) &&
                  (isButtonDisabledforPermissions()) }
            >Save
            </Button></>
            }
          </ButtonSection>
        </Form>
      </div>
    )
  }
  return (
    <Modal open onClose={onClose} areDetailsChanged={
      (JSON.stringify(userSites).replace(/\s/g, '') !== JSON.stringify(defaultSites).replace(/\s/g, '') ||
                user.jobTitle !== jobTitle ||
                  user.firstName !== firstName ||
                  user.lastName !== lastName)
    } setConfirmEditUserDetails={setConfirmEditUserDetails}>

      <Title data-testid='editUserTitle'>User Details</Title>

      {user && !isUserConfirmingDelete && userInfoForm()}

      {isUserConfirmingDelete && (
        <>
          <Text>Are you sure you want to delete this user?</Text>
          <TextBold>This action is permanent.</TextBold>
          <ButtonSection>
            <Button primaryLine fullWidth onClick={resetPromptToDeleteUser}>
              Cancel
            </Button>
            <Button
              primary
              data-testid='deleteUserConfirmButton'
              fullWidth
              onClick={handleDeleteUser}
              isLoading={isLoading}>
              Confirm
            </Button>
          </ButtonSection>
        </>
      )}
    </Modal>
  )
}

UserAdminEditModal.propTypes = {
  userId: PropTypes.string,
  onClose: PropTypes.func,
  reloadUsers: PropTypes.func,
  filteredUserList: PropTypes.array,
  label: PropTypes.string
}

export default UserAdminEditModal
