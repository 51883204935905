import { useUser } from '@/context/UserProvider'
import { useTranslation } from 'react-i18next'

export const useQuckNavigationLinks = () => {
  const { t } = useTranslation()
  const { hasPermission } = useUser()

  const generateLink = (name, url, permission) => ({ name, url, permission })
  const hasBookings = hasPermission('Bookings')
  const hasChart = hasPermission('Chart')
  const hasCalendar = hasPermission('Calendar')
  const hasCreateBookings = hasPermission('CreateBooking')
  const hasFinancial = hasPermission('Financial')
  const hasNews = hasPermission('News')

  const links = [
    generateLink(t('createBooking'), hasCalendar ? '/bookings/calendar' : hasChart ? '/bookings/chart' : null, hasCreateBookings),
    generateLink(t('seeMyBookings'), '/bookings/list', hasBookings),
    generateLink(t('myStatements'), '/financial/statements', hasFinancial),
    generateLink(t('latestNews'), '/news', hasNews)
  ]

  const filteredLinks = links.filter((link) => link.permission)

  return filteredLinks.length === 0 ? null : filteredLinks
}

export default useQuckNavigationLinks