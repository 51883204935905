import React from 'react'
import { PageWrapper } from '@/components/__UI'
import PriceRecommendations from '../../components/__PriceRecommendations/PriceRecommendations/PriceRecommendations'
import { useTranslation } from 'react-i18next'
import { PriceContainer } from './PriceRecommendationsPage.styles'
import useUserBrand from '@/hooks/useUserBrand'

const PriceRecommendationsPage = () => {
  const { t } = useTranslation()
  const {isNovasol} = useUserBrand()

  const title = isNovasol ? t('recommendedDefaultPrices') : t('recommendedStartPrices')

  return (
    <PageWrapper title={title}>
      <PriceContainer>
        <PriceRecommendations />
      </PriceContainer>
    </PageWrapper>

  )
}

export default PriceRecommendationsPage
