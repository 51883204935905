import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useBookingListContext } from '@/context/BookingListProvider'
import { getBookingText } from '@/helpers/bookings'
import { Flex, Box } from 'reflexbox/styled-components'
import { Divider, Modal, Spinner } from '@/components/__UI'
import { ErrorContent } from './CancelledBookingModal.styles'
import { useMutationCancelBooking } from '@/hooks/useQuery'
import {
  PropertyId,
  PropertyName,
  BookingType,
  ConfirmationMessage,
  ErrorMessage
} from '../bookingModals.styles'
import useWindowSize from '@/hooks/useWindowSize'
import { getBookingStateText } from '@/helpers/translations/translations'
import { useTranslation } from 'react-i18next'
import { Button } from '@awaze/design-system.components.button'

const CancelledBookingModal = ({
  data,
  onCancelled,
  onClose,
  mustConfirm,
  onConfirm
}) => {
  const [ hasError, setHasError ] = useState(false)
  const [ isCancelled, setIsCancelled ] = useState(false)
  const [ isCancelling, setIsCancelling ] = useState(true)
  const [ hasNotificationSent, setHasNotificationSent ] = useState(false)
  const [ hasSuppressedNotification, setHasSuppressedNotification ] = useState(false)
  const [ hasConfirmed, setHasConfirmed ] = useState(!mustConfirm)
  const { reloadBookings } = useBookingListContext()
  const { t } = useTranslation()

  const isMounted = useRef(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const { width } = useWindowSize()
  const isDesktop = width > 1024

  const onCancelledFunction = onCancelled
  const hasBookingState = data.state !== undefined
  const bookingType = hasBookingState ? getBookingStateText(t, data.state) : getBookingText(data)

  const { mutate: cancelBooking } = useMutationCancelBooking({
    onSuccess (response) {
      if (isMounted.current) {
        setIsCancelled(response.cancelled)
        setHasError(false)
        setIsCancelling(false)
        setHasNotificationSent(response.hasNotificationSent)
        setHasSuppressedNotification(response.hasSuppressedNotification)

        onCancelledFunction && onCancelledFunction(data)
        onConfirm(data)
        reloadBookings && reloadBookings()
      }
    },
    onError () {
      if (isMounted.current) {
        setIsCancelling(false)
        setHasError(true)
      }
    }
  })

  useEffect(() => {
    if (!isCancelled && isCancelling && hasConfirmed) {
      cancelBooking({
        propertyId: data.propertyId,
        reservationId: data.bookingId
      })
    }
  }, [cancelBooking, data, isCancelled, isCancelling, hasConfirmed])

  const buildMessageForSuccessfulNotification = (hasSuppressedNotification) => {
    if (!hasSuppressedNotification) {
      return (
        // eslint-disable-next-line react/no-unescaped-entities
        <p id='cancelNotificationMessage'>{t('cancelNotificationMessage')}</p>
      )
    }
  }

  const buildButtons = (left, right) => {
    const rightProps = {
      width: left ? [1 / 2] : [1, 1 / 2]
    }

    if (left) {
      rightProps.pl = [2]
    }

    return (
      <Flex justifyContent="center" flexWrap="wrap" width="100%">
        {left && (
          <Box width={[1 / 2]} pr={[2]}>
            <Button
              data-testid={left.id}
              onClick={left.onClick}
              colourScheme='brand-secondary'
              isFullWidth
              text={left.text}
            />

          </Box>
        )}

        <Box {...rightProps}>
          <Button
            colourScheme='brand-primary'
            isFullWidth
            data-testid={right.id}
            onClick={() => {
              right.onClick()
            }}
            {...right.props}
            text={right.text}
          />

        </Box>

      </Flex>
    )
  }

  if (!hasConfirmed) {
    return (
      <Modal open onClose={onClose}>
        <PropertyName>{data.propertyName}</PropertyName>
        <PropertyId>{data.propertyId}</PropertyId>
        <h2 data-testid="cancelBookingModalConfirmationText" style={{ margin: '2rem 1rem', textAlign: 'center' }}>
          {t('cancelBookingModalConfirmationText')}
        </h2>
        {buildButtons(
          { text: t('no'), onClick: onClose, id: 'cancelBookingModalDeclineButton' },
          { text: t('yes'), onClick: () => setHasConfirmed(true), id: 'cancelBookingModalConfirmationButton' }
        )}
      </Modal>
    )
  }

  if (isCancelling && isDesktop) {
    return (
      <Modal open strict>
        <Spinner large />
      </Modal>
    )
  }

  if (hasError) {
    return (
      <Modal open onClose={onClose}>
        <ErrorContent>
          <ErrorMessage data-type="error">
            <p>{t('cancelBookingErrorMessage')}</p>
          </ErrorMessage>
        </ErrorContent>
        {buildButtons(null, { text: t('tryAgain'), onClick: () => setIsCancelling(true) })}
      </Modal>
    )
  }

  return (
    <Modal open onClose={onClose}>
      <BookingType bookingState={data?.state}>{ bookingType }</BookingType>
      <PropertyName>{data.propertyName}</PropertyName>
      <PropertyId>{data.propertyId}</PropertyId>
      { (hasNotificationSent || hasSuppressedNotification)
        ? (
          <ConfirmationMessage data-type="success">
            <h3>{t('cancelBookingSuccessHeader')}</h3>
            {buildMessageForSuccessfulNotification(hasSuppressedNotification)}
          </ConfirmationMessage>
        )
        : ((isCancelling && !isDesktop)
          ? <Spinner large />
          : <ErrorMessage data-type="error">
            <p>
              {t('cancelBookingSuccessWithErrorMessage1')}
              {t('cancelBookingSuccessWithErrorMessage2', { bookingRef: data.bookingId })}
            </p>
          </ErrorMessage>
        )
      }
      <Divider size={4} />
      {buildButtons(null, { text: t('ok'), onClick: onClose, props: { 'data-testid': 'cancelledBookingModalConfirmationButton' } })}
    </Modal>
  )
}

CancelledBookingModal.propTypes = {
  data: PropTypes.object,
  onCancelled: PropTypes.func,
  onClose: PropTypes.func,
  mustConfirm: PropTypes.bool,
  isFromFilter: PropTypes.bool,
  attributesToNotShow: PropTypes.array,
  onConfirm: PropTypes.func
}

export default CancelledBookingModal
