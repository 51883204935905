/* stylelint-disable no-descending-specificity */

import styled from 'styled-components'
import Button from '@/components/__Inputs/Button'

const AcceptRejectTable = styled.table`
margin-top: 1rem;
border-collapse: collapse;

tr{
  border-bottom: 0.5px solid ${({theme}) => theme.colors.tertiary};
  border-collapse: collapse;
}

th{
  font-size: 1rem;
  font-weight: 700;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    font-size: 0.9rem;
    font-weight: 600;
  }
}

td, th {
  padding: 1rem;
  text-align: left;
  padding-right: 10rem;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    padding-right: 1rem;
    overflow-x: hidden;
    padding-left: 0;
  }

  &:nth-child(2){
    padding-right: 1rem;
  }
}

.acceptRejectHeaderContainer{
  display: flex;
  flex-direction: row;

  .optionHeaderAcceptDesktop{
    min-width: 15rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .optionHeaderRejectDesktop{
    min-width: 8rem;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: normal;
  }
  .optionHeaderAccept{
    display: flex;
    justify-content: center;
    min-width: 8rem;
    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      min-width: 4rem;
    }
  }
  .optionHeaderReject{
    font-weight: normal;
    display: flex;
    justify-content: center;
    min-width: 8rem;
    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      min-width: 4rem;
    }
  }
}

.radioBtn{
  text-align: center !important;
}

form{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 8vw;

  .accept{
    min-width: 15rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 35vw;
  }

  .acceptIpad{
    min-width: 8rem;
    align-items: center;
    display: flex;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      min-width: 4rem;
    }
  }

    .reject{
      min-width: 8rem;
      align-items: center;
      display: flex;
      justify-content: center;
    }

  .rejectIpad{
    min-width: 8rem;
    align-items: center;
    display: flex;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      min-width: 4rem;
    }
  }
}



input[type="radio"] {
  appearance: none;
  background-color: ${({theme}) => theme.colors.white};
  margin: 0;
  font: inherit;
  width: 1.2em;
  height: 1.2em;
  border: 0.1em solid ${({theme}) => theme.colors.greyThree};
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em ${({theme}) => theme.colors.secondary};
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
`

const SubmitButton = styled(Button)`
  margin-top: 1rem;
  height: 2.8125rem;
  width: 9vw;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary}; 
  border: none;

    @media (min-width: 600px) and (max-width: 923px) {
    width: 13vw;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 42vw;
  }
  `
const ApprovalMessage = styled.div`
margin-top: 1rem;
`

export { AcceptRejectTable, SubmitButton, ApprovalMessage }
