import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

export const queryKeyPropertyPricingSummary = ['property-pricing-summary']

interface YearData {
    year: number;
    duration: string[];
  }

interface ReferenceData {
    propertyId: string;
    years: YearData[];
  }

interface PropertyPricingSummary {
    showPricingRecommendations: boolean;
    referenceData: ReferenceData[];
    isHidePricingDurations: boolean;
  }

export function useQueryPropertyPricingSummary (options?: UseQueryOptions<PropertyPricingSummary>) {
  const queryKey = queryKeyPropertyPricingSummary

  const queryFn = () => apiFetch('/v1/pricing/recommendations/summary')
  const FIVE_MINUTES = 5 * 60 * 1000

  return useQuery<PropertyPricingSummary>(queryKey, queryFn, { cacheTime: FIVE_MINUTES, refetchOnWindowFocus: false, ...options })
}
