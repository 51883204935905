import React from 'react'
import { Spinner } from '@/components/__UI'
import { ButtonContent, CancelButton, StyledBookingDetails } from './BookingListTable.styles'
import { TrashcanSvg } from '@/components/__SVG'
import { useQueryUserPropertyEntitlements, useQueryBookingSearch } from '@/hooks/useQuery'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import BookingDetails from '../BookingDetails'
import { useTranslation } from 'react-i18next'

export const ExpandedRow = ({ item, onCancel, bookingToCancel, confirmedBookingsToBeCancelled }) => {
  const { t } = useTranslation()
  const { data: bookingDetails, isLoading: isLoadingBookingDetails } = useQueryBookingSearch(item.bookingId)

  const { data: authorisations } = useQueryUserPropertyEntitlements({ propertyId: String(item.propertyId) })

  const canCancel = () => {
    if (!dayjs(item.startDate).isBefore(dayjs(), 'day') && (bookingDetails?.cancellable)) {
      return !!(authorisations?.length && authorisations.find(({ name }) => name === 'cancelBooking'))
    } else {
      return false
    }
  }

  const isThisBookingSelectedToBeCancelled = (bookingToBeCancelled, item) => {
    return JSON.stringify(bookingToBeCancelled) === JSON.stringify(item)
  }

  const isThisBookingCancelled = (item) => {
    return confirmedBookingsToBeCancelled !== undefined && !!confirmedBookingsToBeCancelled.find(obj => JSON.stringify(obj) === JSON.stringify(item))
  }

  return (
    <div className='box'>
      {isLoadingBookingDetails
        ? <Spinner large data-testid="loading-spinner"/>
        : (
          <StyledBookingDetails>
            <BookingDetails
              booking={{...item, ...bookingDetails}}
              showProvisionalBanner={true}
            />
            {canCancel() && !isThisBookingSelectedToBeCancelled(bookingToCancel, item) && !isThisBookingCancelled(item) &&
              <CancelButton data-testid='bookingCancelButton' onClick={ () => onCancel(item) }>
                <ButtonContent>
                  <TrashcanSvg />
                  <p>{t('cancelBooking')}</p>
                </ButtonContent>
              </CancelButton>}
          </StyledBookingDetails>
        )}
    </div>
  )
}

ExpandedRow.propTypes = {
  item: PropTypes.shape({
    propertyId: PropTypes.string.isRequired,
    bookingId: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  bookingToCancel: PropTypes.any,
  confirmedBookingsToBeCancelled: PropTypes.arrayOf(PropTypes.object)
}
