import React, { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { Box } from 'reflexbox/styled-components'
import Calendar from '@/components/Calendar'
import { useQueryBookings, useQueryClosures } from '@/hooks/useQuery'
import { CONSOLIDATED_BOOKING_STATE_CANCELLED } from '@/constants/bookings'
import PropTypes from 'prop-types'

import { GridWrap } from './BookingCalendar.styles'
import { Spinner } from '@/components/__UI'
import useWindowSize from '@/hooks/useWindowSize'

const getCalendarLayout = yearCalendarInput => yearCalendarInput ? [1] : [1, 1, 1 / 3, 1 / 3]
const getCalendarIpadLayout = yearCalendarInput => yearCalendarInput ? [1] : [1, 1, 1 / 2, 1 / 2]

export function BookingCalendarYear ({
  year,
  yearCalendar,
  propertyId,
  unitId,
  setSelectedDay,
  setSelectedBookingID,
  hasAuth,
  setBookings,
  setClosures,
  contractExpiry
}) {
  const { width } = useWindowSize()
  const startDate = `${year - 1}-12-01`
  const endDate = `${year}-12-31`
  const enabled = yearCalendar && !!propertyId

  const calendarLayout = width < 1025 ? getCalendarIpadLayout(yearCalendar) : getCalendarLayout(yearCalendar)

  const { data: bookingsData, isLoading: isLoadingBookings } = useQueryBookings({
    propertyId,
    unitId,
    searchType: 'calendar',
    searchBy: 'arrival',
    startDate,
    endDate
  }, {
    enabled
  })
  const { data: closures, isLoading: isLoadingClosures } = useQueryClosures({ propertyId, unitId, startDate, endDate }, { enabled })
  const loadingBookings = isLoadingBookings || isLoadingClosures

  const bookings = useMemo(() => {
    return bookingsData?.bookings?.filter(x => x.state !== CONSOLIDATED_BOOKING_STATE_CANCELLED) || []
  }, [bookingsData])

  useEffect(() => {
    if (enabled) setBookings(bookings)
  }, [enabled, bookings, setBookings])
  useEffect(() => {
    setClosures(closures)
  }, [enabled, closures, setClosures])

  return (
    <GridWrap>
      {loadingBookings ? (
        <Spinner large />
      ) : (
        [...Array(12).keys()].map(m => {
          const month = m + 1
          const date = dayjs(`${year}-${month}-1`)
          const segments = bookings ? bookings.filter(b => {
            const startDate = dayjs(b.startDate)
            const endDate = dayjs(b.endDate)
            const firstDayOfMonth = dayjs(date).startOf('month')
            const lastDayOfMonth = dayjs(date).endOf('month')

            const overlapsCurrentMonth = startDate.isBefore(lastDayOfMonth, 'day') && endDate.isAfter(firstDayOfMonth, 'day')

            const isSameMonth = startDate.isSame(date, 'month') || endDate.isSame(date, 'month')

            return isSameMonth || overlapsCurrentMonth
          }) : []

          return (
            <Box key={month} width={calendarLayout} mb={2}>
              <Calendar
                hideDays={width < 600}
                date={date}
                onDayClick={setSelectedDay}
                onSegmentClick={({ id }) => setSelectedBookingID(id)}
                segments={segments.map(item => ({
                  id: item.bookingId,
                  state: item.state,
                  startDate: item.startDate,
                  endDate: item.endDate
                }))}
                unavailableDates={[...closures, contractExpiry] || []}
                disabled={!hasAuth('createBooking')}
                yearCalendar={yearCalendar}
                isFirstMonth={month === 1}
              />
            </Box>
          )
        })
      )}
    </GridWrap>
  )
}

BookingCalendarYear.propTypes = {
  year: PropTypes.number.isRequired,
  yearCalendar: PropTypes.bool,
  propertyId: PropTypes.string,
  unitId: PropTypes.string,
  setSelectedDay: PropTypes.func.isRequired,
  setSelectedBookingID: PropTypes.func.isRequired,
  hasAuth: PropTypes.func.isRequired,
  setBookings: PropTypes.func.isRequired,
  setClosures: PropTypes.func.isRequired,
  contractExpiry: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startDateIso: PropTypes.string,
    endDateIso: PropTypes.string
  })
}
