import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch, isApiEnabled } from '@/hooks/useQuery/helpers'

export const queryKeyUserAuth = ['user-authorisations']

export type UserAuthorisations = {
  name: string;
  authorised: boolean;
}[]

export function useQueryUserAuthorisations (options?: UseQueryOptions<UserAuthorisations>) {
  const queryKey = queryKeyUserAuth

  const queryFn = () => apiFetch('v1/users/authorisations')

  return useQuery<UserAuthorisations>(queryKey, queryFn, { staleTime: Infinity, refetchOnWindowFocus: false, placeholderData: [], enabled: isApiEnabled(), ...options })
}
