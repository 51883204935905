import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

type UserPropertyEntitlementsInput = {
  propertyId: string
}

type UserPropertyEntitlements = {
  name: string,
  authorised?: boolean
}[]

export const queryKeyUserEntitlements = (input: UserPropertyEntitlementsInput) => ['user-entitlements', input]

function hasInput (input: UserPropertyEntitlementsInput) {
  return input.propertyId != null
}

export function useQueryUserPropertyEntitlements (input: UserPropertyEntitlementsInput, options?: UseQueryOptions<UserPropertyEntitlements>) {
  const enabled = hasInput(input)
  const queryKey = queryKeyUserEntitlements(input)
  const queryFn = () => apiFetch(`/v1/users/properties/${input.propertyId}/entitlements`).then(mapResponse)

  return useQuery<UserPropertyEntitlements>(queryKey, queryFn, { staleTime: Infinity, refetchOnWindowFocus: false, enabled, ...options })
}

export function mapResponse (response) {
  return response.filter(x => x.authorised)
}
