import styled from 'styled-components'

const Body = styled.tbody`
  max-height: fit-content;
/* MOBILE */
${({ theme }) => theme.mediaQueries.phoneOnly} {
  max-height: fit-content;
}
`
const SiteROW = styled.tr`
background-color: ${({ theme }) => theme.colors.primaryFour};
border: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
/* MOBILE */
${({ theme }) => theme.mediaQueries.phoneOnly} {
  background-color: ${({ theme }) => theme.colors.primaryFour};
  border: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
}
/* iPAD */
@media (min-width: 600px) and (max-width: 1199px) {
  background-color: ${({ theme }) => theme.colors.primaryFour};
  border: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
}
`
const UnitROW = styled.tr`
background-color: ${({ theme }) => theme.colors.white};
  /* MOBILE */
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    background-color: ${({ theme }) => theme.colors.white};
  }
  /* iPAD */
  @media (min-width: 600px) and (max-width: 1199px) {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export { Body, SiteROW, UnitROW }
