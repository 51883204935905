import { useQuery } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

interface MaintenanceStatus {
  isUnderMaintenance: boolean
}

export function useQueryMaintenanceStatus () {
  const queryKey = ['maintenance']
  const queryFn = () =>
    apiFetch('/v1/maintenance')
      .catch(() => ({ isUnderMaintenance: false }))

  return useQuery<MaintenanceStatus>(queryKey, queryFn, {
    enabled: false, // NOTE maintenance only happens once a year, so no reason to poll every minute
    initialData: { isUnderMaintenance: false },
    refetchInterval: 60_000
  })
}
