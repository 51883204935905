import React, { createContext, useContext, useRef } from 'react'
import useGraphQl, { LIST_ALL_CHART_PROPERTY_BOOKINGS } from '@/hooks/useGraphQl'
import { useBookingChartContext } from '@/components/__BookingChartComponents/context'
import { useBookingChartDatesContext } from '@/components/__BookingChartComponents/context/BookingChartDatesProvider'
import { getIsoDate, addDaysToDate, getIsoDateWithoutTimezoneMapping } from '@/helpers/dateUtils'
import toast from '@/helpers/toast'
import { useRefetchSubscribe } from '@/context/RefetchProvider'
import PropTypes from 'prop-types'

export const BookingChartBookingsContext = createContext({
  bookingsByUnit: [],
  loadingBookings: true
})
export const useBookingChartBookingsContext = () => useContext(BookingChartBookingsContext)

export const BookingChartBookingsProvider = ({ children }) => {
  const { filteredSite } = useBookingChartContext()
  const { startDate, numberOfDays } = useBookingChartDatesContext()
  const bookingsByUnit = useRef(null)
  const availabilityByUnit = useRef(null)

  const mapUnitAvailability = (availability) => {
    const unitMappedAvailability = {}
    availability.forEach(a => {
      unitMappedAvailability[a.unitId] = a.unitAvailability
    })

    return unitMappedAvailability
  }

  const populateUnitBookingsAndAvailability = (bookings, availability) => {
    const defaultUnitBookings = {}
    bookings.forEach(booking => {
      if (!(defaultUnitBookings[booking.unitId])) {
        defaultUnitBookings[booking.unitId] = [booking]
      } else {
        defaultUnitBookings[booking.unitId].push(booking)
      }
    })
    bookingsByUnit.current = defaultUnitBookings
    availabilityByUnit.current = mapUnitAvailability(availability)
  }

  const setBookingsByUnit = (bookings) => {
    bookingsByUnit.current = bookings
  }

  const [, loadingBookings, refreshBookings] = useGraphQl({
    query: LIST_ALL_CHART_PROPERTY_BOOKINGS,
    enabled: filteredSite.siteId !== undefined && startDate !== undefined,
    variables: {
      searchType: 'arrival',
      siteId: filteredSite.siteId !== undefined ? filteredSite.siteId : '',
      startDate: startDate !== undefined ? getIsoDateWithoutTimezoneMapping(startDate) : '',
      endDate: startDate !== undefined ? getIsoDate(addDaysToDate(startDate, numberOfDays)) : '',
      callingFeature: 'bookingChart',
      includeAvailability: true
    },
    onSuccess: (data) => {
      populateUnitBookingsAndAvailability(data.bookings, data.availability)
    },
    onError: (err) => {
      toast.error('Failed to retrieve bookings', null, err)
    }
  })

  useRefetchSubscribe(LIST_ALL_CHART_PROPERTY_BOOKINGS, refreshBookings)

  return (
    <BookingChartBookingsContext.Provider value={{
      bookingsByUnit: bookingsByUnit.current,
      loadingBookings: loadingBookings,
      unitAvailability: availabilityByUnit.current,
      setBookingsByUnit: setBookingsByUnit,
      setPerformBookingSearch: refreshBookings
    }}>
      {children}
    </BookingChartBookingsContext.Provider>
  )
}

BookingChartBookingsProvider.propTypes = {
  children: PropTypes.array
}
