import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { Container, StyledDatePicker, CalendarImage, StyledDatePickerLoadingState } from './DatePicker.styles'
import { Spinner } from '@/components/__UI'
import IconCalendar from '@/assets/icons/calendar.svg'

const DatePicker = ({
  className,
  calendarClassName,
  date,
  dateFormat,
  minDate,
  maxDate,
  onChange,
  open,
  onCloseCalendar,
  isLoading,
  ...props
}) => {
  const format = (d) => {
    if (d instanceof Date) return d

    const converted = dayjs(d)
    if (!converted.isValid()) {
      return null
    }
    return converted.toDate()
  }

  const pickerRef = useRef(null)

  const handleRawChange = (e) => {
    e.preventDefault()
  }

  const handleClose = (e) => {
    onCloseCalendar && onCloseCalendar()
    pickerRef.current.setOpen(false)
    e.stopPropagation(e)
  }

  useEffect(() => {
    if (pickerRef.current && open !== undefined) {
      pickerRef.current.setOpen(open)
    }
  }, [open])

  return (
    <Container className={className}>
      <CalendarImage alt="calendar icon" src={IconCalendar}/>
      {isLoading
        ? <StyledDatePickerLoadingState data-testid='datepicker-spinner'>
          <Spinner style={{ marginTop: '-0.375rem' }}/>
        </StyledDatePickerLoadingState>
        : <StyledDatePicker
          ref={pickerRef}
          selected={format(date)}
          defaultValue={format(date)}
          onChange={d => onChange(dayjs(d))}
          onChangeRaw={handleRawChange}
          dateFormat={dateFormat || 'dd/MM/yyyy'}
          calendarClassName={`${calendarClassName || ''} date-picker`}
          minDate={format(minDate)}
          maxDate={format(maxDate)}
          onCloseCalendar={handleClose}
          disabledKeyboardNavigation
          {...props}
        />}
    </Container>
  )
}

DatePicker.propTypes = {
  className: PropTypes.string,
  calendarClassName: PropTypes.string,
  dateFormat: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  open: PropTypes.bool,
  onChange: PropTypes.func,
  onCloseCalendar: PropTypes.func,
  isLoading: PropTypes.bool
}

export default DatePicker
