import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

interface BookingQuoteResponse {
  hasBookingExcessCharge: boolean;
  bookingExcessChargeAmount: number;
  holidayPrice: number;
  hasHolidayPrice: boolean;
  allowBooking: boolean;
  currency: string;
  extras: Extra[];
}

interface Extra {
  id: string;
  name: string;
  cost: number;
  quantitySelectionMode: 'Compulsory' | 'Optional' | 'Defaulted';
  maximumQuantity: number;
  defaultQuantity: number;
}

interface BookingQuoteQueryParams {
  propertyId: string;
  fromDate: string;
  toDate: string;
  unitId?: string;
}

export function useQueryBookingQuote (
  { propertyId, fromDate, toDate, unitId }: BookingQuoteQueryParams,
  options?: UseQueryOptions<BookingQuoteResponse>
) {
  const queryKey = ['quotation-details', propertyId, fromDate, toDate, unitId]

  const queryFn = () => {
    let url = `/v1/properties/${propertyId}/bookings/quote`

    const params = new URLSearchParams()
    params.append('fromDate', fromDate)
    params.append('toDate', toDate)
    if (unitId) {
      params.append('unitId', unitId)
    }

    url += `?${params}`

    return apiFetch(url)
  }

  return useQuery<BookingQuoteResponse>(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    ...options
  })
}
