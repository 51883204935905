import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { getEnvironment } from '@/helpers/environment'
import { PageWrapper, Spinner } from '@/components/__UI'

function NotFoundPage ({ redirectPath, brand }) {
  const location = useLocation()

  const parsedRedirectPath = redirectPath || '/'
  const redirectTo = new URLSearchParams(location.search).get('redirectTo')
  const updatedRedirectPath = redirectTo ? `${parsedRedirectPath}?redirectTo=${redirectTo}` : parsedRedirectPath

  const shouldRedirect = brand === 'novasol'
  const isLocalhost = getEnvironment() === 'localhost'
  const willRedirect = shouldRedirect && !isLocalhost

  useEffect(() => {
    if (willRedirect) {
      window.location.href = location.pathname
    }
  }, [willRedirect, location.pathname])

  if (shouldRedirect) {
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    )
  }

  return (<Navigate to={updatedRedirectPath} replace />)
}

NotFoundPage.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  brand: PropTypes.string
}

export default NotFoundPage
