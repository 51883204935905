import styled from 'styled-components'
import { Flex, Box } from 'reflexbox/styled-components'
import { Button } from '@awaze/design-system.components.button'

const GridWrap = styled(Flex)`
    margin: 1em 0;
    flex-wrap: wrap;
    border-radius: ${({theme}) => theme.borderRadius};
    
    ${({theme}) => theme.mediaQueries.phoneOnly} {
      margin: 0;
    }

`

const StyledPreviousButton = styled(Button)`
 ${({ theme }) => theme.fonts.Link300}

    @media (max-width: 1024px) {
      margin-top: 16px;
      margin-bottom: 16px;
      margin-left: auto;
      margin-right: auto;
      display: block;
}
${({theme}) => theme.mediaQueries.phoneOnly} {
      margin-top: 24px;
      margin-bottom: 8px;
    }
  `

const CalendarWrapper = styled.div`
  ${({yearCalendar, theme}) => yearCalendar ? `
    background-color: ${theme.colors.white};
    padding: 0.625rem;
    border-radius: 6px;
    border: 0.5px solid ${theme.colors.tertiaryButtonBorderHover};

    ${Box} {
      margin-bottom: 0;
    }
  ` : `
    ${theme.mediaQueries.phoneOnly} {
      margin: 0 -18px;
    }
`}
`

const Details = styled.div`
  ${({ theme }) => `
    margin: 1em 0;

    td {
      padding: 0.5em 0;
    }

    ${theme.mediaQueries.phoneOnly} {
      td {
        font-size: 0.9em;
      }
    }
  `}
`

const Year = styled.h2`
  text-align: center;
  margin: 0 16px;
`

export { CalendarWrapper, GridWrap, Details, Year, StyledPreviousButton }
