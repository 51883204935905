import { useUser } from '@/context/UserProvider'
import { useNavigation, NavItem } from './useNavigation'

type User = {
  attributes: {
    brand: string
    productMarkets: string[]
  }
}

export function isPermittedByPermissions (item: NavItem, hasPermission: (permission: string) => boolean) {
  return hasPermission(item?.permissions)
}

export function isPermittedByBrand (item: NavItem, user: User | null) {
  if (Array.isArray(item.brand)) {
    return item.brand.includes(user?.attributes?.brand ?? '')
  }
  return true
}

export function isPermittedByProductMarket (item: any, user: User | null) {
  
  let isValidNavigationBasedOnProductMarket = true
  
  if (Array.isArray(item.productMarkets)) {    
    isValidNavigationBasedOnProductMarket = item.productMarkets.some(
      productMarket => user?.attributes?.productMarkets?.includes(productMarket)
    )
  }

  if(Array.isArray(item.excludedProductMarkets)){
    const matchingExcludedMarket = item.excludedProductMarkets.some(
      productMarket => user?.attributes?.productMarkets?.includes(productMarket)
    )

    if(matchingExcludedMarket){
      isValidNavigationBasedOnProductMarket = false
    }
  }

  return isValidNavigationBasedOnProductMarket
}

// Return list of nav items and links, filtered by permission, brand, and product market
export function usePermittedNavigation () {
  const { user, hasPermission } = useUser()
  const {
    NAV_HEADER_ITEMS,
    NAV_PRE_HEADER_ITEMS
  } = useNavigation()

  const filterByPermission = item => isPermittedByPermissions(item, hasPermission)
  const filterByBrand = item => isPermittedByBrand(item, user)
  const filterByProductMarket = item => isPermittedByProductMarket(item, user)

  const filterLinks = links => {
    return links
      .filter(filterByPermission)
      .filter(filterByBrand)
      .filter(filterByProductMarket)
  }

  const filterNavItems = items => {
    return items
      .filter(filterByPermission)
      .filter(filterByBrand)
      .filter(filterByProductMarket)
      .map(item => ({
        ...item,
        links: item.links && filterLinks(item.links)
      }))
  }

  return {
    NAV_HEADER_ITEMS: filterNavItems(NAV_HEADER_ITEMS),
    NAV_PRE_HEADER_ITEMS: filterNavItems(NAV_PRE_HEADER_ITEMS)
  }
}
