import React from 'react'
import { RadioGroup, RadioGroupProps } from '@awaze/design-system.components.radio-group'
export interface SelectRadioGroupDSProps extends RadioGroupProps {
  label?: string | false;
  isLoading?: boolean;
  loadingText?: string;
}
export function SelectRadioGroupDS ({
  ...props
}: Readonly<SelectRadioGroupDSProps>) {
  return (
    <RadioGroup
      {...props}
    />
  )
}
