import styled from 'styled-components'
import { PropertyDropdownDS } from '@/components/__Inputs/PropertyDropdown'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'
import { PropertyUnitDropdownDS } from '@/components/__Inputs/PropertyDropdown/PropertyUnitDropdownDS'

export const PageDescriptionSection = styled.div`

  ${({theme}) => theme.mediaQueries.tablet} {
    & > p {
      padding-right: 1rem;
    }
  }
`

export const PropertyDropdown = styled(PropertyDropdownDS)`
  margin: 0;
  height: 3rem;
`

export const PropertyUnitDropdown = styled(PropertyUnitDropdownDS)`
  margin: 0;
  height: 3rem;
`

export const YearDropdown = styled(SelectDropdown)``

export const DropdownWrapper = styled.div`
  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    display: flex;
    flex: 1;
    z-index: 2;
    margin: 1rem 0;
    align-items: end;

    & > div {
      flex: 1;
    }
  }

  ${({theme}) => theme.mediaQueries.iPadAir} {
    max-width: 100%;
    width: 100%;
    margin-right: 1rem;
  }
`

export const PropertyDropdownWrapper = styled.div`
  max-width: min(350px, 35%);
  width: 100%;
  margin-right: 1rem;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
  max-width: 100%;
  margin: 1rem 0 1.25rem;
  }
`
export const PropertyUnitDropdownWrapper = styled.div`
  max-width: min(350px, 22%);

  @media (min-width: 600px) and (max-width: 767px) {
   max-width: min(350px, 37%);
  }

  @media (min-width: 768px) and (max-width: 1300px) {
   max-width: min(350px, 35%);
  }
  
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
  max-width: 100%
  }
`

export const YearDropdownWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin-right: 1rem;
  display: none;

  ${({ theme }) => theme.mediaQueries.mediumLargeScreen} {
    display: block;
  }
`

export const BookingLegend = styled.div`
    ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }

  @media (min-width:  600px) and (max-width: 1024px) {
    display: flex;
  }
`

export const LegendWrapper = styled.div`
  margin: 1rem 0;

  @media (min-width: 600px) and (max-width: 649px) {
    width: 34%;
  }

  @media (min-width: 650px) and (max-width: 765px) {
    width: 39%;
  }
  @media (min-width: 766px) and (max-width: 1199px) {
    width: 44%;
  }
  
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 100%;
  }
`
