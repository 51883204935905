import styled from 'styled-components'
import TableHeader from '../../Table/components/TableHeader'
import TableCell from '../../Table/components/TableCell'

export const TableHeaderMobile = styled(TableHeader)`
    font-size: 1rem;
`
export const TableHeaderCellMobile = styled(TableCell)`
    min-width: 0;
    flex: 1 1 0px;
    padding: 0;
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const TableHeaderDesktopDIV = styled.div`
    z-index: 1;
    background: ${({theme}) => theme.colors.white};
    @media screen and (min-width: 600px) and (max-width: 900px) {
        padding-top: 1rem;
    }
    ${({ theme }) => theme.mediaQueries.phoneOnly} {
        padding-bottom: 0.5rem;
        box-shadow: rgba(0,0,0,0.2) 0px 2px 4px 0px, rgba(0,0,0,0.1) 0px 0.3px 0.5px 0px;
    }
    @media screen and (min-width: 601px) and (max-width: 1025px) {
        padding: 1rem;
        box-shadow: rgba(0,0,0,0.2) 0px 2px 4px 0px, rgba(0,0,0,0.1) 0px 0.3px 0.5px 0px;
    }
`

export const TableHeaderDesktop = styled(TableHeader)`
    text-align: left;
    justify-content: flex-start;
`
export const MobileHeader = styled.div`
    background-color: ${({theme}) => theme.colors.white};
    padding-bottom: 1rem;
    box-shadow: rgba(0,0,0,0.2) 0px 2px 4px 0px, rgba(0,0,0,0.1) 0px 0.3px 0.5px 0px;
`

export const Currency = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    padding: 8px 12px;
    margin-bottom: 0.625rem;
    background: #EBF1FF;
    border-radius: 4px;

    svg{
        margin-top: 0.3rem;
    }
    span{
        margin-left: 0.5rem;
    }

    @media screen and (min-width: 600px) and (max-width: 900px) {
        margin-left: 1rem;
    }
    @media screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 0.5rem;
    }
`
