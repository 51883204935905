import useUserBrand from '@/hooks/useUserBrand'
import React, { useEffect, useState } from 'react'
import { createCookie } from '@/helpers/cookies'
import { getEnvironment } from '@/helpers/environment'
import { useLocation } from 'react-router-dom'
import { PageWrapper, Spinner } from '@/components/__UI/'
import dayjs from 'dayjs'

const USER_MARKET_COOKIE_NAME = 'Drupal.visitor.awaze_user_market'
const USER_MARKET_UNKNOWN_MARKET = 'Unknown'
const USER_LANGUAGE_COOKIE_NAME = 'Drupal.visitor.awaze_user_language'
const USER_LANGUAGE_DEFAULT_VALUE = 'en'

const setCorrectMarketValue = (market: string) => {
  if(market)
    return market

  return USER_MARKET_UNKNOWN_MARKET
}

export const OwnerServiceRedirect = () => {
  const location = useLocation()
  const { isAwaze, userProductMarkets } = useUserBrand()
  const [cookiesSet, setCookiesSet] = useState(false)
  
  useEffect(() => {
    if (isAwaze) {
      const marketForUser = (userProductMarkets && userProductMarkets.length > 0) ? setCorrectMarketValue(userProductMarkets[0]) : USER_MARKET_UNKNOWN_MARKET
      const cookieExpiration = dayjs().add(1, 'day').date()
      createCookie(USER_MARKET_COOKIE_NAME, marketForUser, cookieExpiration, '/')
      createCookie(USER_LANGUAGE_COOKIE_NAME, USER_LANGUAGE_DEFAULT_VALUE, cookieExpiration, '/')
      setCookiesSet(true)
    }
  }, [isAwaze, userProductMarkets])

  const shouldRedirect = true
  const isLocalhost = getEnvironment() === 'localhost'
  const willRedirect = shouldRedirect && !isLocalhost

  useEffect(() => {
    if (willRedirect && cookiesSet) {
      window.location.replace(location.pathname)
    }
  }, [willRedirect, location.pathname, cookiesSet])

  return (
    <PageWrapper>
      <Spinner />
    </PageWrapper>
  )
}
