import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch, ApiFetchError } from '@/hooks/useQuery/helpers'

export const queryKeyUserContracts = ['user-contracts']

interface UserContractResponse {
    ContractId: number;
    ContractName: string;
    TermsAndConditionsContent: string;
}

export function useQueryUserContracts (enabled: boolean, options?: UseQueryOptions<UserContractResponse[]>) {
  const queryKey = queryKeyUserContracts

  const queryFn = () => apiFetch('v1/users/contracts').catch(async (err) => {
    if (err instanceof ApiFetchError) {
      const status = err.response.status
      const json = await err.response.json()
      if (status === 404 && json.error === 'Contracts not found for the user id') {
        return []
      }
    }
  })

  return useQuery<UserContractResponse[]>(queryKey, queryFn, { staleTime: Infinity, refetchOnWindowFocus: false, enabled: enabled, ...options })
}
