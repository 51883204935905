/* eslint-disable */

import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import Collapse from '@kunukn/react-collapse'
import SearchBar from '@/components/__Inputs/SearchBar'
import FAQsItem from './FAQsItem'
import BulbIcon from './BulbIcon'
import { FaqList, FaqListDesc, SearchMatches } from './FAQs.styles'

const FAQsList = ({faqData = [], filter, showSearch, smallBar, showLabel = false}) => {
  const [searchText, setSearchText] = useState('')
  const [faqList, setFaqList] = useState([])
  const [searchToggle, setSearchToggle] = useState(false)
  const [filterToggle, setFilterToggle] = useState(false)
  const [openCollapse, setOpenCollapse] = useState(false)

  useEffect(() => {
    setFaqList(filteredFaqs())
    setFilterToggle(!filterToggle)

    // eslint-disable-next-line
  }, [filter, faqData])

  useEffect(() => {
    setFaqList(filteredFaqs())
    if (searchText.length > 0) {
      setSearchToggle('searchOpen')
      setOpenCollapse(true)
    } else {
      setSearchToggle('searchClose')
      setOpenCollapse(false)
    }
    // eslint-disable-next-line
  }, [searchText])

  const filteredFaqs = () => {
    let list = filter && filter !== 'all' ? faqData.filter(faq => faq.tags.includes(filter)) : faqData
    list = searchText !== '' ? list.filter(faq => {
      let content = faq.content.props.children
      if (typeof content !== 'string') {
        content = content.map(c => {
          if (typeof c === 'string') {
            return c.toLowerCase().includes(searchText.toLowerCase())
          } else {
            return false
          }
        }).some((c) => c === true)
      } else {
        content = content.toLowerCase().includes(searchText.toLowerCase())
      }
      return (
        faq.title.toLowerCase().includes(searchText.toLowerCase()) ||
        content
      )
    }) : list

    return list
  }

  return (
    <FaqList smallBar={smallBar}>
      {showSearch &&
        <>
          <SearchBar
            name='searchBar'
            setSearchText={setSearchText}
          />

          <Collapse isOpen={openCollapse} data-is-open={openCollapse}>
            {faqList.length > 0
              ? <SearchMatches>{faqList.length} match{faqList.length > 1 && 'es'}</SearchMatches>
              : <SearchMatches>No Matches Found</SearchMatches>
            }
          </Collapse>
        </>
      }
      { showLabel && <FaqListDesc><BulbIcon width='16px' height='24px' style={{marginRight: '1rem'}}/>Some things you may need to know</FaqListDesc>}
      {faqList.map((faq, index) => <FAQsItem key={index} faq={faq} searchToggle={searchToggle} filterToggle={filterToggle} smallBar={smallBar} />)}
    </FaqList>
  )
}

FAQsList.propTypes = {
  filter: PropTypes.string,
  faqData: PropTypes.array,
  showLabel: PropTypes.bool,
  showSearch: PropTypes.bool,
  smallBar: PropTypes.bool
}

export default FAQsList
