import styled from 'styled-components'
import { Modal } from '@/components/__UI'
import { Form } from '@/components/__Inputs'
import { Heading } from '@awaze/design-system.components.text'
import { Button } from '@awaze/design-system.components.button'

const BookingSection = styled.div`
  font-size: 0.8rem;
`

const AlertWarning = styled.div`
  margin-bottom: 1rem;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  
 ${({ theme }) => theme.mediaQueries.phoneOnlyDS} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.phoneOnlyDS} {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.tabletUpDS} {
    max-width: 16rem;
  }
`
const SubSectionFullWidth = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.phoneOnlyDS} {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const SectionLabelUnit = styled.div`
  margin-bottom: 2rem;
  font-weight: 600;
`
export const SectionTitle = styled.div`
${({ theme }) => theme.fonts.Text300}
margin-bottom: 0.5rem;
font-weight: 500;
`
export const SectionTitleTotal = styled.div`
  font-weight: 700;
  font-size: 1rem;
`

const SectionLabel = styled.div`
  font-weight: 600;
  border: 1px solid red;
`

const SectionData = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  

  input {
    margin-bottom: 0.6rem;
  }
`

const SectionDropdown = styled.div`
  font-size: 1rem;
`
export const QuoteSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0.5rem 0 1rem 0;

  & > * {
    flex: 1;
  }
`
export const SpinnerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  & > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`

const Quote = styled.div`
  height: auto; 
  width: auto; 
  background: ${({ theme }) => theme.colors.quoteBackground};
  display: flex;
  flex-direction: column; 
  margin-bottom: 1.5rem;
  padding-top: .5rem;
  padding-left: .8rem; 
  border-radius: .3rem;

  h4 {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.1rem;
  }

  .priceContainer{ 
    height: 2rem; 
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    .priceContainer{ 
      height: 3rem; 
      justify-content: space-between;
      padding-right: 1rem;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    flex-direction: column-reverse;
    gap: 1rem;
  }
`

export const StyledButton = styled(Button)`
  flex: 1; 

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 100%;
  }
`

export const BookingModal = styled(Modal)`
  max-width: 600px;
`
export const StyledHeading = styled(Heading)`
  margin-top: 1rem
`
export const BookingForm = styled(Form)``

export { BookingSection, Section, SectionLabel, SectionData, SectionDropdown, SubSectionFullWidth, Quote, AlertWarning, SubSection }
