import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { GET_USER_PROPERTY_PRICING, graphQlFetch } from '@/hooks/useGraphQl'

type userPropertyPricing = {
  propertyId: string;
  numberNight: string;
  startYear: string;
  endYear: string;
}

interface PricingDataRow {
    weekCommencing: string;
    miniSeason: string;
    upperCorridor: number;
    lowerCorridor: number;
    originalCustomerPrice: number;
    currentCustomerPrice: number;
    potentialIncome: number;
    actualCustomerIncome: number;
    bookedDuration: number;
    percentChange: number;
    style: string;
  }

  interface UserPropertyPricesEntitlements {
    propertyId: number;
    startYear: number;
    endYear: number;
    numberOfNights: number;
    floorPrice: number;
    pricingDataRows: PricingDataRow[];
  }

export const queryKeyUserPricingEntitlements = (input: userPropertyPricing) => ['user-property-pricing', input]

export function useQueryUserPropertyPricing (input: userPropertyPricing, enabled: boolean = true, options?: UseQueryOptions<UserPropertyPricesEntitlements>) {
  const hasAllRequiredProperties = (input: userPropertyPricing) => {
    return Object.values(input).every(value => value != null && value !== '')
  }

  const allValuesPresent = hasAllRequiredProperties(input)

  const queryKey = queryKeyUserPricingEntitlements(input)
  const queryFn = () => graphQlFetch({ query: GET_USER_PROPERTY_PRICING, variables: input })
    .then(data => {
      return data
    })

  return useQuery<UserPropertyPricesEntitlements>(queryKey, queryFn, { staleTime: Infinity, refetchOnWindowFocus: false, enabled: enabled && allValuesPresent, ...options })
}
