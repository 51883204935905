import { isToday, addDaysToDate } from '@/helpers/dateUtils'
import Theme from '../../../global/Theme'

export function getGridRowStyle (index, numberOfCells, startDate, isGridRow = true) {
  const rightShadowStyle = isGridRow
    ? {
      backgroundImage: `linear-gradient(-270deg, white 0 60%, ${Theme.colors.bgImgFunctions}, 
      ${Theme.colors.radioBtnBoxShadow})`,
      borderRight: `0.5px solid ${Theme.colors.radioBtnBoxShadow}`
    }
    : {
      backgroundImage: `linear-gradient(-270deg, ${Theme.colors.bgImgFunctions} 0px, 
      ${({ theme }) => theme.colors.bgImgFunctions} 60%, ${Theme.colors.radioBtnBoxShadow})`,
      borderRight: `0.5px solid ${Theme.colors.radioBtnBoxShadow}`
    }

  const currentDayBorder = {
    borderLeft: '2px solid black',
    backgroundColor: `${Theme.colors.currentDayBorderBG}`,
    borderRight: `0.5px solid ${Theme.colors.propertyListBorders}`
  }

  const currentDayBorderWithShadowRight = {
    borderLeft: '2px solid black',
    backgroundImage: `linear-gradient(-270deg, ${Theme.colors.currentDayBorderBG} 0 60%, 
    ${Theme.colors.radioBtnBoxShadow}, ${Theme.colors.radioBtnBoxShadow})`,
    borderRight: `0.5px solid ${Theme.colors.radioBtnBoxShadow}`
  }

  const pickedDate = {
    backgroundColor: `${Theme.colors.pickedDateBG}`,
    border: `0.5px solid ${Theme.colors.radioBtnBoxShadow}`,
    borderLeft: `2px solid ${Theme.colors.pickedDateBorder}`
  }

  const dateFromIndex = addDaysToDate(startDate, index)
  /* istanbul ignore else */
  if (index === (numberOfCells - 1) && !isToday(dateFromIndex)) {
    return rightShadowStyle
  } else if (index === 0 && isToday(dateFromIndex)) {
    /* istanbul ignore next */
    return currentDayBorder
  } else if (index === (numberOfCells - 1) && isToday(dateFromIndex)) {
    /* istanbul ignore next */
    return currentDayBorderWithShadowRight
  } else if (index === 2 && !isToday(dateFromIndex)) {
    return pickedDate
  }

  if (isToday(dateFromIndex)) {
    return currentDayBorder
  }

  return {}
}

export function getBookingStateTypeColor (state, type) {
  /* istanbul ignore else */
  if (state === 'Provisional') return `${Theme.colors.bookingTypeProvisional}`
  else if (state === 'Confirmed' && type === 'Customer') return `${Theme.colors.bookingTypeCustomer}`
  else if ((state === 'Confirmed' && type === 'Owner') || (state === 'Owner Commission' || state === 'Unchecked Owner' || state === 'Owner Reserved')) return `${Theme.colors.bookingTypeOwner}`
  else return 'transparent'
}

export function startReservationOnGridStyle (index) {
  const noBoxShadow = 'none'
  const currentBoxShadow = '2px 0 0 0'
  /* istanbul ignore next */
  if (index === 1) {
    return noBoxShadow
  }
  return currentBoxShadow
}

export function fullReservationOnGridStyle (index) {
  const noBoxShadow = 'none'
  const currentBoxShadow = '1px 0 0 0'
  /* istanbul ignore next */
  if (index === 1) {
    return noBoxShadow
  }
  return currentBoxShadow
}
